
import './App.css';
import Header from './pages/header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Overview from './pages/overview';
import Tokens from './pages/tokens';
import Pairs from './pages/pairs';
import Accounts from './pages/accounts';
import TokenDetail from './pages/tokenDetails';
import PairDetail from './pages/pairDetails';
import AccountDetail from './pages/accountDetails';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { ClipLoader } from 'react-spinners';

function App() {

  const savedMode = localStorage.getItem('mode') || 'dark';
  const [mode, setMode] = useState(savedMode);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    document.body.setAttribute('data-theme', mode);
    localStorage.setItem('mode', mode);
    const scrollbarColor = getScrollbarColor(mode);
    const scrollbarTrackColor = getScrollbarTrackColor(mode);
    document.documentElement.style.setProperty('--scrollbar-color', scrollbarColor);
    document.documentElement.style.setProperty('--scrollbar-track-color', scrollbarTrackColor);
  }, [mode]);

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  function getScrollbarColor(mode) {
    switch (mode) {
      case 'dark':
        return '#FFFFFF';
      case 'light':
        return '#23242A';
      default:
        return '#FFFFFF'; // Default color
    }
  }

  function getScrollbarTrackColor(mode) {
    switch (mode) {
      case 'dark':
        return '#23242A';
      case 'light':
        return '#FFFFFF';
      default:
        return '#23242A'; // Default color
    }
  }



  return (
    <>
      <Router>
        {loading ? (
          <div className={`flex justify-center items-center h-screen ${mode === 'light' ? 'bg-white' : 'bg-[#23242A]'}`} >
            {/* <ClipLoader color={'#27AE60'} loading={loading} size={50} /> */}
            <img src="/asset/images/navLogo.png" alt="logo" className="w-12 animate-bounce" />
          </div>
        ) : (
          <div className={mode === 'light' ? 'light-mode' : 'dark-mode'}>
            <Header toggleMode={toggleMode} mode={mode} />
            <Routes>
              <Route exact path='/' element={<Overview mode={mode} />} ></Route>
              <Route exact path='/tokens' element={<Tokens mode={mode} />} ></Route>
              <Route exact path='/token/:address' element={<TokenDetail mode={mode} />} ></Route>
              <Route exact path='/pairs' element={<Pairs mode={mode} />} ></Route>
              <Route exact path='/pair/:address' element={<PairDetail mode={mode} />} ></Route>
              <Route exact path='/accounts' element={<Accounts mode={mode} />} ></Route>
              <Route exact path='/account/:account' element={<AccountDetail mode={mode} />} ></Route>
            </Routes>
          </div>
        )}
        <ToastContainer />
      </Router >
    </>
  );
}

export default App;
