import React from 'react'
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { toK } from '../allFunction/commonFunction';
import { formattedNum } from '../allFunction/commonFunction';


const AreaChartComponent = ({ mode, data, dataKey }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" style={{ padding: '10px', zIndex: '100px' }}>
            <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 10,
                    right: 0,
                    left: 3,
                    bottom: 5,
                }}
            >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={false}
                    minTickGap={80}
                    tickMargin={14}
                    tick={{
                        fill: mode === 'light' ? 'black' : 'white',
                        fontSize: '12px'
                    }}
                />
                <YAxis
                    orientation="right"
                    type="number"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(tick) => '$' + toK(tick)}
                    tick={{
                        fill: mode === 'light' ? 'black' : 'white',
                        fontSize: '12px'
                    }}

                />
                <Tooltip cursor={{ fill: 'transparent', opacity: 0.1 }}
                    formatter={(val) => formattedNum(val, true)}
                    contentStyle={{
                        padding: '10px 14px',
                        borderRadius: 10,
                        borderColor: 'black',
                        color: 'black',
                    }} />
                <Area type="monotone" dataKey={dataKey} stroke="#33D778" fill="#114f29" />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default AreaChartComponent