import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CustomNoRowsOverlay, convertTokentDigit, convertUSDDigit, getMonthInWordWithDate, showErrorToast, timeAgo, truncateAddress } from '../../allFunction/commonFunction';
import { fetchAccountChartDetail, fetchAccountPositionDetail, fetchAccountTransactionDetail, fetchAllPositonData, fetchAccountWalletDetail } from '../../api/account';
import AreaChartComponent from '../../component/AreaChart';
import LineChartComponent from '../../component/LineChart';
import { accountPositionColumn } from '../../constant/data';
import { defaultPageSize } from '../../constant/variable';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Select, MenuItem, FormControl } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SearchDropdown from '../../component/SearchDropdown';

const AccountDetail = (props) => {

    const platformUrl = process.env.REACT_APP_PLATFORM_URL
    const platformButtonText = process.env.REACT_APP_PLATFORM_BUTTON_TEXT

    const [allTransactionData, setAllTransactionData] = useState([]);
    const [allPositionData, setAllPositionData] = useState([]);
    const [accountWalletData, setAccountWalletData] = useState({});
    const [type, setType] = useState('all')
    const [pairAddress, setPairAddress] = useState('')
    const [periodType, setPeriodType] = useState('month')
    const [accountData, setAccountData] = useState({ liquidityUSD: 0, feesUSD: 0 })
    const [allAccountData, setAllAccountData] = useState({ liquidityUSD: 0, feesUSD: 0 })
    const [allWalletStatsData, setAllWalletStatsData] = useState({})

    //chart state
    const [liquidityChartData, setLiquidityChartData] = useState([])
    const [feesChartData, setFeesChartData] = useState([])
    const [chartType, setChartType] = useState('liquidity')

    //loader state
    const [loading, setLoading] = useState(true);
    const [transactionDataLoader, setTransactionDataLoader] = useState(true);
    const [accountPositionDataLoader, setAccountPositionDataLoader] = useState(true);

    const { mode } = props;
    const { account } = useParams();

    const transactionRows = allTransactionData.map((row, index) => ({ ...row, index: index + 1 }));
    const positionRows = allPositionData.map((row, index) => ({ ...row, index: index + 1 }));

    const transactionsColumns = [
        {
            field: 'transactionDescription',
            headerName: (
                <div className='space-x-2 ml-1'>
                    <button onClick={() => handleClick('all')} className={`${type === 'all' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>All</button>
                    <button onClick={() => handleClick('swap')} className={`${type === 'swap' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Swaps</button>
                    <button onClick={() => handleClick('mint')} className={`${type === 'mint' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Adds</button>
                    <button onClick={() => handleClick('burn')} className={`${type === 'burn' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Removes</button>
                </div>
            ),
            minWidth: 200,
            sortable: false,
            disableRowSelectionOnClick: true,
            renderCell: (params) => {
                return (<div className="text-blue-500 hover:text-blue-600 ml-1"><Link to={`${platformUrl}/tx/${params.row.transactionHash}`} target='_blank'>{params.row.transactionDescription}</Link></div>)
            }
        },
        {
            field: 'totalUSD',
            headerAlign: 'right',
            headerName: <div className='hover:text-gray-400'>Total Value</div>,
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                const totalUSD = Number(params.row.totalUSD);
                let formattedTotalUSD = convertUSDDigit(totalUSD);

                // if (Math.abs(totalUSD) < 1000) {
                //     formattedTotalUSD = totalUSD.toFixed(2);
                // } else {
                //     formattedTotalUSD = totalUSD.toLocaleString(undefined, { maximumFractionDigits: 0 });
                // }

                return (
                    <div className='text-right'>
                        {formattedTotalUSD}
                    </div>
                );
            }
        },
        {
            field: 'displayAmount0',
            headerName: <div className='hover:text-gray-400 text-right'>Token Amount</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            renderCell: (params) => {
                const displayAmount0 = Number(params.row.displayAmount0);
                let formatteddisplayAmount0 = convertTokentDigit(displayAmount0);


                return (
                    <div className='text-right'>
                        {formatteddisplayAmount0} {params.row.token0Symbol}
                    </div>
                );
            }
        },
        {
            field: 'displayAmount1',
            headerName: <div className='hover:text-gray-400 text-right'>Token Amount</div>,
            minWidth: 160,
            flex: 1,

            headerAlign: 'right',
            renderCell: (params) => {
                const displayAmount1 = Number(params.row.displayAmount1);
                let formatteddisplayAmount1 = convertTokentDigit(displayAmount1);

                // if (Math.abs(displayAmount1) < 1000) {
                //     formatteddisplayAmount1 = displayAmount1.toFixed(4);
                // } else {
                //     formatteddisplayAmount1 = displayAmount1.toLocaleString(undefined, { maximumFractionDigits: 0 });
                // }

                return (
                    <div className='text-right'>
                        {formatteddisplayAmount1} {params.row.token1Symbol}
                    </div>
                );
            }
        },
        {
            field: 'accountAddress',
            headerName: <div className='hover:text-gray-400'>Account</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            sortable: false,
            renderCell: (params) => (<div className="text-blue-500 hover:text-blue-600 text-right"><Link to={`${platformUrl}/address/${params.row.accountAddress}`} target='_blank'>{truncateAddress(params.value)}</Link></div>)
        },
        {
            field: 'transactionDatetime',
            headerName: <div className='hover:text-gray-400 mr-1'>Time</div>,
            minWidth: 160,
            flex: 1,

            headerAlign: 'right',
            renderCell: (params) => {
                return (
                    <div className='text-right mr-1'>
                        {timeAgo(params.value)}
                    </div>
                );
            }
        }

    ];

    const handleClick = async (transactionType) => {
        try {
            setType(transactionType);
            await getTransactionData(transactionType);
        } catch (error) {
            showErrorToast(error.message)
        }
    };

    const getPositionData = async () => {
        try {
            setAccountPositionDataLoader(true);
            const positionData = await fetchAccountPositionDetail(account)
            if (!positionData.success) {
                showErrorToast(positionData.message)
            }
            setAllPositionData(positionData.data);
        } catch (error) {
            showErrorToast(error.message)
            setAllPositionData([])
        } finally {
            setAccountPositionDataLoader(false);
        }
    }

    const getAccountInnerData = async () => {
        try {
            const accountData = await fetchAccountWalletDetail(account)
            if (!accountData.success) {
                showErrorToast(accountData.message)
            }
            setAccountWalletData(accountData.data[0]);
        } catch (error) {
            showErrorToast(error.message)
            setAccountWalletData([])
        }
    }

    const getTransactionData = async (transactionType) => {
        try {
            setTransactionDataLoader(true);
            const transactionData = await fetchAccountTransactionDetail(transactionType, account)
            if (!transactionData.success) {
                showErrorToast(transactionData.message)
            }
            setAllTransactionData(transactionData.data);

        } catch (error) {
            showErrorToast(error.message)
            setAllTransactionData([])
        } finally {
            setTransactionDataLoader(false);
        }
    }

    const getLiquidityChartData = async (periodType, pairAddress) => {
        try {
            setLoading(true)
            const data = await fetchAccountChartDetail(periodType, pairAddress, account)
            if (!data.success) {
                showErrorToast(data.message)
            }
            const formattedData = data.data.map(entry => {
                const date = getMonthInWordWithDate(entry.dayStartDateTime);
                return { Liquidity: entry.liquidityUSD, date: date };
            });
            setLiquidityChartData(formattedData)
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    }

    const getFeesChartData = async (periodType, pairAddress) => {
        try {
            setLoading(true)
            const data = await fetchAccountChartDetail(periodType, pairAddress, account)
            if (!data.success) {
                showErrorToast(data.message)
            }
            const formattedData = data.data.map(entry => {
                const date = getMonthInWordWithDate(entry.dayStartDateTime);
                return { Fees: entry.feesUSD, date: date };
            });
            setFeesChartData(formattedData)
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    }

    const getChartData = (chartType, periodType, pairAddress) => {
        try {
            const address = pairAddress === 'All Positions' ? '' : pairAddress
            if (chartType === 'liquidity') {
                getLiquidityChartData(periodType, address)
            } else if (chartType === 'fees') {
                getFeesChartData(periodType, address)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const handleClickChart = (type) => {
        try {
            if (type !== chartType) {
                setChartType(type)
                getChartData(type, periodType, pairAddress)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const handlePeriodType = (type) => {
        try {
            if (type !== periodType) {
                setPeriodType(type)
                getChartData(chartType, type, pairAddress)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    // const handlePairAddressChange = (event) => {
    //     try {
    //         const selectedAddress = event.target.value;
    //         if (selectedAddress === 'All Positions') {
    //             setPairAddress('');
    //             handleClickChart('liquidity', periodType);
    //         } else {
    //             setPairAddress(selectedAddress);
    //             if (selectedAddress !== 'All Positions') {
    //                 const pairAddData = allPositionData.find(x => x.pairAddress === selectedAddress);
    //                 if (pairAddData) {
    //                     setAccountData({ liquidityUSD: pairAddData.liquidityUSD, feesUSD: pairAddData.feesUSD });
    //                 } else {
    //                     throw new Error('Pair address data not found.');
    //                 }
    //             } else {
    //                 setAccountData({ liquidityUSD: allAccountData.liquidityUSD, feesUSD: allAccountData.feesUSD });
    //             }
    //         }
    //     } catch (error) {
    //         showErrorToast(error.message)
    //     }
    // };

    const handlePairAddressChange = (event) => {
        try {
            debugger
            const selectedAddress = event.target.value;
            if (selectedAddress === 'All Positions') {
                setPairAddress('');
                setChartType('liquidity')
                setAccountData({ liquidityUSD: allAccountData.liquidityUSD, feesUSD: allAccountData.feesUSD });
                getChartData('liquidity', periodType, selectedAddress);
            } else {
                setPairAddress(selectedAddress);
                const pairAddData = allPositionData.find(x => x.pairAddress === selectedAddress);
                if (pairAddData) {
                    setAccountData({ liquidityUSD: pairAddData.liquidityUSD, feesUSD: pairAddData.feesUSD });
                    getChartData(chartType, periodType, selectedAddress);
                } else {
                    throw new Error('Pair address data not found.');
                }
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    };

    const fetchAllAccountData = async () => {
        try {
            const data = await fetchAllPositonData(account);
            if (!data.success) {
                showErrorToast(data.message)
            }
            setAllAccountData({ liquidityUSD: data.data.totalLiquidityUSD, feesUSD: data.data.totalFeesUSD })
            setAccountData({ liquidityUSD: data.data.totalLiquidityUSD, feesUSD: data.data.totalFeesUSD })
            setAllWalletStatsData(data.data)
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    // const handleOptionClick = (position) => {
    //     setPairAddress(position.pairAddress);
    // };

    useEffect(() => {
        fetchAllAccountData()
        getAccountInnerData()
        getPositionData()
        getLiquidityChartData(periodType, pairAddress)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getTransactionData(type)
        // eslint-disable-next-line
    }, [type])

    return (
        <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#23242A]'} mb-10`}>
            <div className='p-4 lg:ml-[240px] lg:max-w-[100%]'>
                <div className="lg:max-w-[1440px] lg:mx-auto" >
                    <div className='flex justify-between items-center mt-4 w-full'>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'}  text-sm`}><Link to='/accounts'>Accounts</Link> <ArrowForwardIosIcon sx={{ fontSize: '14px', marginLeft: '3px', marginRight: '1px' }} /><Link to={`${platformUrl}/address/${account}`} className='cursor-pointer text-blue-500  lg:mx-1 md:mx-1 sm:mx-1 mx-0 lg:text-sm md:text-sm sm:text-sm text-[12px]' target='_blank'>{account}</Link></p>
                        <div className="relative lg:block md:block sm:hidden hidden w-80">
                            <SearchDropdown mode={mode} account={account} />
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-7 w-full'>
                        <div className=' items-center '>
                            <p className={`${mode === 'light' ? 'text-black' : 'text-white'} text-2xl font-semibold`}>{truncateAddress(account)}</p>
                            <p className={`${mode === 'light' ? 'text-gray-700' : 'text-white'} text-sm`}><Link to={`${platformUrl}/address/${account}`} className='cursor-pointer' target='_blank'>{platformButtonText}</Link></p>
                        </div>
                        {/* <div className=' items-center lg:mt-0 md:mt-0 sm:mt-5 mt-5'>
                            <TurnedInNotIcon className='text-[#636368]' />
                        </div> */}
                    </div>

                    <FormControl fullWidth sx={{ mt: 3 }} className={`account-position ${mode === 'light' ? 'bg-white' : 'bg-[#1F2026] border-[#40444F]'}`} >
                        <Select
                            labelId="accountPosition-label"
                            id="accountPosition"
                            value={pairAddress || 'All Positions'}
                            onChange={handlePairAddressChange}
                            sx={{ color: mode === 'light' ? 'black' : 'white' }}
                            MenuProps={{
                                sx: { fontSize: '14px' }
                            }}
                        >
                            <MenuItem
                                value={'All Positions'}
                                sx={{
                                    backgroundColor: mode === 'light' ? '#ffffff' : '#22242A',
                                    color: mode === 'light' ? 'black' : 'white',
                                    '&:hover': {
                                        backgroundColor: mode === 'light' ? '#F7F8FA' : '#2C2F36'
                                    },
                                    '&:focus': {
                                        backgroundColor: mode === 'light' ? '#F7F8FA' : '#2C2F36',
                                        outline: 'none',
                                    },
                                    py: '10px',
                                    fontSize: '14px'
                                }}
                            >
                                <div className='h-[24px] flex items-center gap-2'>
                                    <TrendingUpIcon />  All Positions
                                </div>
                            </MenuItem>
                            {allPositionData.map((position) => (
                                <MenuItem
                                    key={position.pairName}
                                    value={position.pairAddress}
                                    sx={{
                                        backgroundColor: mode === 'light' ? '#ffffff' : '#22242A',
                                        color: mode === 'light' ? 'black' : 'white',
                                        '&:hover': {
                                            backgroundColor: mode === 'light' ? '#F7F8FA' : '#2C2F36'
                                        },
                                        py: '10px',
                                        fontSize: '14px'
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={position.tokenIcon0 || '/asset/images/datagrid-icon.png'} alt="icon" style={{ borderRadius: '50%', height: '20px', marginRight: '8px', zIndex: 10 }} />
                                        <img src={position.tokenIcon1 || '/asset/images/datagrid-icon.png'} alt="icon" style={{ borderRadius: '50%', height: '20px', marginLeft: '-15px', marginRight: '8px' }} />
                                        {position.pairName} Position
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className={` ${mode === 'light' ? 'bg-white' : 'bg-[#1F2026] border-[#40444F]'} w-full mt-5 p-4 border rounded-lg`}>
                        <div className={`${mode === 'light' ? 'text-black' : 'text-white'} flex lg:justify-normal sm:justify-normal justify-between gap-10`}>
                            <div>
                                <p className='mb-[3px]'>Liquidity (Including Fees)</p>
                                <p className='text-2xl font-medium'>{convertUSDDigit(accountData.liquidityUSD)} </p>
                            </div>
                            <div>
                                <p className='mb-[3px]'>Fees Earned (Cumulative)</p>
                                <p className='text-green-600 text-2xl font-medium'>{convertUSDDigit(accountData.feesUSD)}</p>
                            </div>
                        </div>
                    </div>

                    {/* <div className={`lg:h-full md:h-96 sm:h-72 h-72 mt-5 border rounded-lg ${mode === 'light' ? 'bg-white ' : 'bg-[#1F2026] border-[#40444F]  '}`}>
                        <BarChart
                            xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C', 'group D', 'group E', 'group F', 'group G'] }]}
                            series={[{ data: [4, 3, 5, 2, 4, 3, 1] }, { data: [1, 6, 3, 2, 5, 4, 3] }, { data: [2, 5, 6, 3, 5, 2, 1] }]}
                            colors={['#27AE60']}
                        />
                    </div> */}
                    <div className={`border ${mode === 'light' ? 'text-black bg-white' : 'text-white bg-[#1F2026] border-[#40444f]'} rounded-md p-2 mt-6`}>
                        <div className={`lg:flex md:flex sm:flex flex-none justify-between`}>
                            {pairAddress ? <div className='space-x-2'>
                                <button onClick={() => handleClickChart('liquidity', periodType)} className={`${chartType === 'liquidity' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm`}>Liquidity</button>
                                <button onClick={() => handleClickChart('fees', periodType)} className={`${chartType === 'fees' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm`}>Fees</button>
                            </div> : <div className={`${mode === 'light' ? 'text-black' : 'text-white'} ps-2 flex items-center text-sm`}><p>Liquidity Value</p></div>}
                            <div className='space-x-2 sm:mt-0 mt-2'>
                                <button className={`${periodType === "week" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('week')}>1W</button>
                                <button className={`${periodType === "month" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('month')}>1M</button>
                                <button className={`${periodType === "all" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('all')}>All</button>
                            </div>
                        </div>
                    </div>
                    {/* <div ref={areaChartContainerRef} className={`w-full lg:h-96 md:h-96 sm:h-72 h-72 mt-5 rounded-lg border ${mode === 'light' ? '' : ' border-[#40444f]'}`}></div> */}
                    <div className={`relative  w-full lg:h-96 md:h-96 sm:h-72 h-72 mt-2 rounded-lg border ${mode === 'light' ? '' : 'bg-[#1F2026] border-[#40444f]'}`}>
                        {/* <div className={`absolute w-full top-5 px-4 mb-5`}>
                            <div className='lg:flex md:flex sm:flex flex-none justify-between'>
                                <div className='space-x-2'>
                                    <button onClick={() => handleClickChart('liquidity', periodType)} className={`${chartType === 'liquidity' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'text-black hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600 text-white'} border rounded-md px-3 py-1 text-sm`}>Liquidity</button>
                                    <button onClick={() => handleClickChart('fees', periodType)} className={`${chartType === 'volume' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'text-black hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600 text-white'} border rounded-md px-3 py-1 text-sm`}>Volume</button>
                                </div>
                                <div className='space-x-2 sm:mt-0 mt-2'>
                                    <button className={`${periodType === "week" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200 text-black' : 'border-[#40444f] hover:bg-gray-600 text-white'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('week')}>1W</button>
                                    <button className={`${periodType === "month" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200 text-black' : 'border-[#40444f] hover:bg-gray-600 text-white'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('month')}>1M</button>
                                    <button className={`${periodType === "all" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200 text-black' : 'border-[#40444f] hover:bg-gray-600 text-white'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('all')}>All</button>
                                </div>
                            </div>
                        </div> */}
                        {loading && <div className="flex justify-center items-center h-full">
                            <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                        </div>}
                        {(chartType === 'liquidity' && !loading) ? <AreaChartComponent dataKey={"Liquidity"} data={liquidityChartData} mode={mode} /> : <></>}
                        {(chartType === 'fees' && !loading) ? <LineChartComponent data={feesChartData} mode={mode} /> : <></>}
                    </div>

                    <div>
                        <p className={`text-lg font-semibold ${mode === 'light' ? 'text-black' : 'text-white'} font-semibold mt-8`}>Positions</p>
                    </div>

                    <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} w-full  mt-5`}>
                        {transactionDataLoader ? (
                            <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                <div className="flex justify-center items-center w-full h-40">
                                    <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                </div>
                            </div>
                        ) : (
                            <DataGrid
                                sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                rows={positionRows}
                                columns={accountPositionColumn}
                                rowHeight={75}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: defaultPageSize,
                                        },
                                    },
                                }}
                                showColumnVerticalBorder={true}
                                getRowId={(row) => row.index}
                                pageSizeOptions={[defaultPageSize]}
                                disableRowSelectionOnClick
                                autoHeight
                                disableColumnResize={true}
                                slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                            />
                        )}
                    </div>

                    {/* <div>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} font-semibold mt-10 text-xl`}>Liquidity Mining Pools</p>
                    </div>
                    <div className={`block w-full mt-5 p-4 ${mode === 'light' ? 'bg-white' : 'bg-[#1F2026] border-[#40444F] '}  border rounded-lg`}>
                        <p className={`mb-2 text-base font-semibold ${mode === 'light' ? 'text-black' : 'text-white'} `}>No Staked Liquidity.</p>
                        <p className={`${mode === 'light' ? 'bg-[#caf1db] text-[#27AE60] hover:bg-[#b7eecf]' : 'bg-[#1E3558] text-[#4886dd] hover:bg-[#28436b] '} text-sm  py-1 rounded-md cursor-pointer font-semibold w-24 flex justify-center`}>Learn More</p>
                    </div> */}

                    <div>
                        <p className={`text-lg ${mode === 'light' ? 'text-black' : 'text-white'} font-semibold mt-10`}>Transactions</p>

                        <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} w-full  mt-5`}>
                            {accountPositionDataLoader ? (
                                <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                    <div className="flex justify-center items-center w-full h-40">
                                        <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                    </div>
                                </div>
                            ) : (
                                <DataGrid
                                    sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                    rows={transactionRows}
                                    columns={transactionsColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: defaultPageSize,
                                            },
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    getRowId={(row) => row.index}
                                    pageSizeOptions={[defaultPageSize]}
                                    disableRowSelectionOnClick
                                    autoHeight
                                    disableColumnResize={true}
                                    slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                                />
                            )}
                        </div>
                    </div>

                    <div className='mt-10'>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} font-semibold mt-10 text-xl`}>Wallet Stats</p>
                    </div>

                    <div className={`w-full mt-5 p-5 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] text-white border-[#40444F]'}  border rounded-lg`}>
                        <div className='lg:flex justify-start'>
                            <div>
                                <p className='text-[14px] font-normal'>Total Value Swapped</p>
                                <p className='text-base font-semibold mt-2'>{convertUSDDigit(allWalletStatsData.totalSwapVolumeUSD)}</p>
                            </div>
                            <div>
                                <p className='lg:mx-12 lg:mt-0 mt-5  text-[14px] font-normal'>Total Fees Paid</p>
                                <p className='lg:mx-12 text-base font-semibold mt-2'>{convertUSDDigit(allWalletStatsData.totalFeesPaidUSD)}</p>
                            </div>
                            <div>
                                <p className='lg:mt-0 mt-5 text-[14px] font-normal'>Total Transactions</p>
                                <p className='text-base font-semibold mt-2'>{allWalletStatsData.totalTransactions ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default AccountDetail
