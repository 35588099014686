import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { searchTokenAndPair } from '../api/search';
import { showErrorToast } from '../allFunction/commonFunction';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const SearchDropdown = ({ mode, placeholder, account }) => {
    const [pairData, setPairData] = useState([{ firstLetter: 'Pairs', name: 'No result' }])
    const [tokenData, setTokenData] = useState([{ firstLetter: 'Tokens', name: 'No result' }])
    const [showMorePairs, setShowMorePairs] = useState(false);
    const [showMoreTokens, setShowMoreTokens] = useState(false);
    const [pairsOffset, setPairsOffset] = useState(0);
    const [tokensOffset, setTokensOffset] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    // const [options, setOptions] = useState([{ firstLetter: 'Pairs', name: 'No result' }, { firstLetter: 'Tokens', name: 'No result' }])

    const location = useLocation()

    const navigate = useNavigate()

    const handleInputChange = (event, newInputValue) => {

        if (newInputValue !== 'undefined - No result') {
            setInputValue(newInputValue);
        }
    };

    const handleChange = (e, value) => {
        if (value !== null && value.name !== 'No result') {
            if (value.address && value.name) {
                if (value.firstLetter === 'Pairs') {
                    navigate(`/pair/${value.address}`);
                } else if (value.firstLetter === 'Tokens') {
                    navigate(`/token/${value.address}`);
                }
                setSelectedValue(value.name);
                if (location.pathname.includes('/pair/') || location.pathname.includes("/token/"))
                    window.location.reload();
            } else {
                setSelectedValue(value);
                // setInputValue(value)
            }
        } else {
            setSelectedValue('');
            setInputValue('')
        }
    };

    const fetchSearchData = async () => {
        try {
            setLoading(true);
            const response = await searchTokenAndPair({ q: inputValue })

            setPairData(response.pairData)
            setTokenData(response.tokenData)
            if (!response.success) {
                showErrorToast(response.message)
            }
            // const autoCompleteOptions = [
            //     ...response.pairData.map(option => ({
            //         firstLetter: 'Pairs',
            //         ...option
            //     })),
            //     ...response.tokenData.map(option => ({
            //         firstLetter: 'Tokens',
            //         ...option
            //     }))
            // ];
            // setOptions(autoCompleteOptions)
        } catch (error) {
            showErrorToast(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchSearchData()
        // eslint-disable-next-line
    }, [inputValue])

    const handleClose = () => {
        setInputValue('')
    }

    let options;

    if (loading) {
        options = [{ firstLetter: 'Pairs', name: 'No result' }, { firstLetter: 'Tokens', name: 'No result' }];
    } else {
        if (tokenData.length === 0 && pairData.length === 0) {
            options = [
                { firstLetter: 'Pairs', name: 'No result' },
                { firstLetter: 'Tokens', name: 'No result' } // Add 'No result' option under 'Tokens' group
            ];
        } else if (tokenData.length === 0) {
            options = [
                ...pairData.slice(0, pairsOffset + (showMorePairs ? pairData.length : 5)).map(option => ({
                    firstLetter: 'Pairs',
                    ...option
                })),
                { firstLetter: 'Tokens', name: 'No result' } // Add 'No result' option under 'Tokens' group
            ];
        } else if (pairData.length === 0) {
            options = [
                { firstLetter: 'Pairs', name: 'No result' },// Add 'No result' option under 'Pairs' group
                ...tokenData.slice(0, tokensOffset + (showMoreTokens ? tokenData.length : 5)).map(option => ({
                    firstLetter: 'Tokens',
                    ...option
                }))
            ];
        } else {
            options = [
                ...pairData.slice(0, pairsOffset + (showMorePairs ? pairData.length : 5)).map(option => ({
                    firstLetter: 'Pairs',
                    ...option
                })),
                ...tokenData.slice(0, tokensOffset + (showMoreTokens ? tokenData.length : 5)).map(option => ({
                    firstLetter: 'Tokens',
                    ...option
                }))
            ];
        }
    }

    return (
        <div className='search-dropdown'>
            <Autocomplete
                id="grouped-demo"
                options={options}
                groupBy={(option) => option.firstLetter}
                onChange={(e, newVal) => handleChange(e, newVal)}
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => {
                    handleInputChange(e, newInputValue);
                }}
                getOptionLabel={(option) => `${(option.address || inputValue).trim()} ${(option.address ? '-' : '').trim()} ${(option.name || '').trim()}`}
                freeSolo
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault(); // Prevent the default Enter key behavior
                        // handleChange(e, e.key)
                        setSelectedValue(inputValue)
                        setInputValue(inputValue)
                    }
                }}
                sx={{
                    width: '100%',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: mode === 'light' ? '1px solid gray' : 'none',
                    },
                    borderRadius: '10px',
                    backgroundColor: mode === 'light' ? '#ffffff' : '#0E0F12',
                    height: '40px',
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps, style: { color: mode === 'light' ? 'black' : 'white' }, placeholder: placeholder,
                            endAdornment: (<div style={{ marginRight: '8px' }}>
                                {(!selectedValue && !inputValue) ? <SearchIcon style={{ color: 'gray' }} size={25} /> : <CloseIcon style={{ color: 'gray', cursor: 'pointer' }} onClick={handleClose} />}
                                {/* {params.InputProps.endAdornment} */}
                            </div>)
                        }}
                    // {params.InputProps.endAdornment} // Hide close icon when selectedValue is not null
                    />
                )}
                PaperComponent={({ children }) => (
                    <Paper sx={{ backgroundColor: mode === 'light' ? '#ffffff' : '#0E0F12', color: mode === 'light' ? 'black' : 'white' }}>
                        {children}
                    </Paper>
                )}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <div className='z-20  px-4 search-list-section' style={{ color: mode === 'light' ? '#8C96A1' : 'gray' }}>{params.group}</div>
                        <ul className='ps-1 mt-3  search-list-item'>{params.children}</ul>
                        {!loading && params.group === 'Pairs' && pairData.length > 5 && (
                            <button className='text-blue-600 px-4 mt-5 mb-6 text-sm' onClick={() => {
                                if (pairsOffset + 5 >= pairData.length) {
                                    setShowMorePairs(!showMorePairs);
                                    setPairsOffset(0);
                                } else {
                                    setPairsOffset(pairsOffset + 5);
                                }
                            }}>See {showMorePairs ? 'less' : 'more...'}</button>
                        )}
                        {!loading && params.group === 'Tokens' && tokenData.length > 5 && (
                            <button className='text-blue-600 px-4 mt-5  text-sm' style={{ marginBottom: '20px' }} onClick={() => {
                                if (tokensOffset + 5 >= tokenData.length) {
                                    setShowMoreTokens(!showMoreTokens);
                                    setTokensOffset(0);
                                } else {
                                    setTokensOffset(tokensOffset + 5);
                                }
                            }}>See {showMoreTokens ? 'less' : 'more...'}</button>
                        )}
                    </li>
                )}
                renderOption={(props, option) => {

                    return (
                        // <li {...props} className={`py-3 px-1 flex cursor-pointer ${mode === 'dark' ? 'hover:bg-[#2C2F36]' : 'hover:bg-gray-100'} items-center`} style={{ padding: '10px' }}>
                        <li {...props} className={`py-3 px-1 flex cursor-pointer ${mode === 'dark' ? 'hover:bg-[#2C2F36]' : 'hover:bg-gray-100'} items-center`} style={{ padding: '10px', pointerEvents: option.name === 'No result' ? 'none' : 'auto' }}>
                            {!loading && (option.firstLetter === 'Pairs' && option.name !== 'No result' ? (
                                <>
                                    <img src={option.tokenIcon0 || '/asset/images/datagrid-icon.png'} alt="icon" className='rounded-full h-6 mx-2 z-10' />
                                    <img src={option.tokenIcon1 || '/asset/images/datagrid-icon.png'} alt="icon" className='rounded-full h-6' style={{ marginLeft: '-15px' }} />
                                </>
                            ) : (
                                <>
                                    {!loading && option.tokenIcon ? (
                                        <img src={option.tokenIcon} alt="icon" className='rounded-full h-6 mx-2' />
                                    ) : option.name !== 'No result' && (
                                        <img src='/asset/images/datagrid-icon.png' alt='icon' className='mx-2 h-6' />
                                    )}
                                </>
                            ))}
                            <span className={`${option.name === 'No result' && 'hover:bg-transparent'} ml-2 `} >{option.name}</span>
                        </li>
                    );
                }}
            />

            {/* <button type="button" className="absolute inset-y-0 end-0 " style={{ marginRight: '8px' }}>
                    <svg className="w-4 h-4 me-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path className='text-gray-400' stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </button> */}
        </div>
    )
}

export default SearchDropdown
