import axios from "axios"

const apiBaseUrl = process.env.REACT_APP_APIBASEURL

export const fetchPairDetail = async () => {
    const response = await axios.get(`${apiBaseUrl}/pair/pair-list`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchPairAddressDetail = async (pairAddress) => {
    const response = await axios.get(`${apiBaseUrl}/pair/pair-details/${pairAddress}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchPairTransactionDetail = async (pairAddress, actionType) => {

    const response = await axios.post(`${apiBaseUrl}/pair/pair-transactions/${pairAddress}`, {
        actionType
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchPairChartDetail = async (pairAddress, periodType) => {

    const response = await axios.post(`${apiBaseUrl}/pair/pair-chart/${pairAddress}`, {
        periodType
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchPairHourlyChartDetail = async (pairAddress, periodType) => {

    const response = await axios.post(`${apiBaseUrl}/pair/pair-hourly-chart/${pairAddress}`, {
        periodType
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}


