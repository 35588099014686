import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CustomNoRowsOverlay, convertTokentDigit, convertUSDDigit, getDigitSign, getMonthInWordWithDate, showErrorToast, timeAgo, truncateAddress, formatDateWithTime } from '../../allFunction/commonFunction';
import { fetchPairAddressDetail, fetchPairChartDetail, fetchPairHourlyChartDetail, fetchPairTransactionDetail } from '../../api/pair';
import AreaChartComponent from '../../component/AreaChart';
import BarChartComponent from '../../component/BarChart';
import SearchDropdown from '../../component/SearchDropdown';

const PairDetail = (props) => {

    const [copiedPairAddress, setCopiedPairAddress] = useState('');
    const [copiedWiseAddress, setCopiedWiseAddress] = useState('');
    const [copiedEthAddress, setCopiedEthAddress] = useState('');
    const [isPairCopyIconShown, setIsPairCopyIconShown] = useState(true);
    const [isWiseCopyIconShown, setIsWiseCopyIconShown] = useState(true);
    const [isEthCopyIconShown, setIsEthCopyIconShown] = useState(true);
    const [allPairAddressData, setAllPairAddressData] = useState({});
    const [allTransactionPairData, setAllTransactionPairData] = useState([])
    const [areaPrice, setAreaprice] = useState({ price: '', time: '', isTime: false })

    //loader State
    const [loading, setLoading] = useState(true);
    const [pairTransactionDataLoader, setPairTransactionDataLoader] = useState(true)

    //Chart State
    const [type, setType] = useState('all')
    const [periodType, setPeriodType] = useState('week')
    const [chartType, setChartType] = useState('liquidity')
    const [chartObject, setChartObject] = useState(null)
    const [series, setSeries] = useState(null);
    const [liquidityChartData, setLiquidityChartData] = useState([])
    const [volumeChartData, setVolumeChartData] = useState([])

    // const addLiquidityUrl = process.env.REACT_APP_ADDLIQUIDITYURL
    const tradeUrl = process.env.REACT_APP_TRADE_URL
    const platformUrl = process.env.REACT_APP_PLATFORM_URL
    const platformButtonText = process.env.REACT_APP_PLATFORM_BUTTON_TEXT

    const { address } = useParams()
    const { mode } = props;

    const areaChartContainerRef = useRef(null);

    const pairTransactionRows = allTransactionPairData.map((row, index) => ({ ...row, index: index + 1 }));

    const transactionsColumns = [
        {
            field: 'transactionDescription',
            headerName: (
                <div className='space-x-2 ml-1'>
                    <button onClick={() => handleClick('all')} className={`${type === 'all' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>All</button>
                    <button onClick={() => handleClick('swap')} className={`${type === 'swap' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Swaps</button>
                    <button onClick={() => handleClick('mint')} className={`${type === 'mint' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Adds</button>
                    <button onClick={() => handleClick('burn')} className={`${type === 'burn' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Removes</button>
                </div>
            ),
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                return (<div className="text-blue-500 hover:text-blue-600 ml-1"><Link to={`${platformUrl}/tx/${params.row.transactionHash}`} target='_blank'>{params.row.transactionDescription}</Link></div>)
            }
        },
        {
            field: 'totalUSD',
            headerAlign: 'right',
            headerName: <div className='hover:text-gray-400'>Total Value</div>,
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                const totalUSD = Number(params.row.totalUSD);
                let formattedTotalUSD = convertUSDDigit(totalUSD);

                // if (Math.abs(totalUSD) < 1000) {
                //     formattedTotalUSD = totalUSD.toFixed(2);
                // } else {
                //     formattedTotalUSD = totalUSD.toLocaleString(undefined, { maximumFractionDigits: 0 });
                // }

                return (
                    <div className='text-right'>
                        {formattedTotalUSD}
                    </div>
                );
            }
        },
        {
            field: 'displayAmount0',
            headerName: <div className='hover:text-gray-400 text-right'>Token Amount</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            renderCell: (params) => {
                const tokenUSD = Number(params.row.displayAmount0);
                let formattedTotalUSD = convertTokentDigit(tokenUSD);
                return (
                    <div className='text-right'>
                        {formattedTotalUSD} {params.row.token0Symbol}
                    </div>
                );
            }
        },
        {
            field: 'displayAmount1',
            headerName: <div className='hover:text-gray-400 text-right'>Token Amount</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            renderCell: (params) => {
                const tokenUSD = Number(params.row.displayAmount1);
                let formattedTotalUSD = convertTokentDigit(tokenUSD);
                return (
                    <div className='text-right'>
                        {formattedTotalUSD} {params.row.token1Symbol}
                    </div>
                );
            }
        },
        {
            field: 'accountAddress',
            headerName: <div className='hover:text-gray-400'>Account</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            sortable: false,
            renderCell: (params) => (<div className="text-blue-500 hover:text-blue-600 text-right"><Link to={`${platformUrl}/address/${params.row.accountAddress}`} target='_blank'>{truncateAddress(params.value)}</Link></div>)
        },
        {
            field: 'transactionDatetime',
            headerName: <div className='hover:text-gray-400 mr-1'>Time</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            renderCell: (params) => {
                return (
                    <div className='text-right mr-1'>
                        {timeAgo(params.value)}
                    </div>
                );
            }
        }

    ];

    const handleClick = (transactionType) => {
        try {
            setType(transactionType)
            getPairTransactionData(transactionType)
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const getPairAddressData = async () => {
        try {
            const pairData = await fetchPairAddressDetail(address)
            if (!pairData.success) {
                showErrorToast(pairData.message)
            }
            setAllPairAddressData(pairData.data);
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const getPairTransactionData = async (transactionType) => {
        try {
            setPairTransactionDataLoader(true)
            const pairTransactionData = await fetchPairTransactionDetail(address, transactionType)
            if (!pairTransactionData.success) {
                showErrorToast(pairTransactionData.message)
            }
            setAllTransactionPairData(pairTransactionData.data);
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setPairTransactionDataLoader(false)
        }
    }

    const getLiquidityChartData = async (periodType) => {

        try {
            setLoading(true)
            const pairChartData = await fetchPairChartDetail(address, periodType);
            if (!pairChartData.success) {
                showErrorToast(pairChartData.message)
            }
            const formattedData = pairChartData.data.map(entry => {
                const date = getMonthInWordWithDate(entry.dayStartDateTime);
                return { Liquidity: entry.reserveUSD, date: date };
            });

            if (formattedData.length <= 0) {
                return;
            }

            setLiquidityChartData(formattedData)

            if (series) {
                try {
                    chartObject.removeSeries(series);
                } catch (e) { }
            }

        } catch (error) {
            showErrorToast(error.message)
            // chartOfLiquidity = null;
        } finally {
            setLoading(false)
        }
    };

    const getVolumeChartData = async (type) => {
        try {
            setLoading(true)
            const pairChartData = await fetchPairChartDetail(address, type);
            if (!pairChartData.success) {
                showErrorToast(pairChartData.message)
            }
            const formattedData = pairChartData.data.map(entry => {
                const date = getMonthInWordWithDate(entry.dayStartDateTime);
                return { Volume: entry.dailyVolumeUSD, date: date };
            });

            if (formattedData.length <= 0) {
                return;
            }

            setVolumeChartData(formattedData)

            if (series) {
                try {
                    chartObject.removeSeries(series);
                } catch (e) { }
            }

        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    };

    const getRate0HourlyChartData = async (periodType) => {
        try {
            setLoading(true)
            const pairData = await fetchPairHourlyChartDetail(address, periodType)
            if (!pairData.success) {
                showErrorToast(pairData.message)
            }
            const formattedData = pairData.data.map(entry => {
                return { open: entry.openToken0Price, high: entry.closeToken0Price, low: entry.closeToken0Price, close: entry.closeToken0Price, time: entry.hourStartUnix / 1000 };
            });

            if (series) {
                try {
                    chartObject.removeSeries(series);
                } catch (e) { }
            }

            if (formattedData.length <= 0) {
                return;
            }

            const chartOptions = {
                layout: {
                    textColor: mode === 'light' ? 'black' : 'white',
                    background: { type: 'solid', color: 'transparent' },
                    border: '1px solid red',

                },
                grid: {
                    vertLines: {
                        visible: true,
                    },
                    horzLines: {
                        visible: true,
                    },
                }
            };
            // let chartData = getChartObject()
            let chartObjectData

            try {
                if (!chartObject) {
                    chartObjectData = createChart(areaChartContainerRef.current, chartOptions);
                } else {
                    chartObjectData = chartObject
                }
                setChartObject(chartObjectData);
                //chartData  = chartObjectData;
            } catch (error) {
                showErrorToast(error.message)
            }
            if (chartObjectData) {
                let seriesData = chartObjectData.addCandlestickSeries({});
                seriesData.setData(formattedData);
                chartObjectData.timeScale().fitContent();
                setSeries(seriesData)
                chartObjectData.subscribeCrosshairMove(param => {

                    // if (param.time) {
                    //     const data = param.seriesData.get(seriesData);
                    //     setAreaprice({ time: data.time, price: convertUSDDigit(data.high), isTime: true });
                    // } else {
                    //     setAreaprice({ time: '', price: 0, isTime: false });
                    // }

                    if (param.time) {
                        const dataIterator = param.seriesData.values();
                        const dataArray = [...dataIterator];
                        if (dataArray.length > 0) {
                            const data = dataArray[0];
                            setAreaprice({ time: data.time * 1000, price: convertTokentDigit(data.close), isTime: true });
                        } else {
                            setAreaprice({ time: '', price: 0, isTime: false });
                        }
                    } else {
                        setAreaprice({ time: '', price: 0, isTime: false });
                    }
                    setChartObject(chartObjectData);

                });
            }
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    };

    const getRate1HourlyChartData = async (periodType) => {

        try {
            setLoading(true)
            const pairData = await fetchPairHourlyChartDetail(address, periodType)
            if (!pairData.success) {
                showErrorToast(pairData.message)
            }
            const formattedData = pairData.data.map(entry => {
                return { open: entry.openToken1Price, high: entry.closeToken1Price, low: entry.closeToken1Price, close: entry.closeToken1Price, time: entry.hourStartUnix / 1000 };
            });

            if (series) {
                try {
                    chartObject.removeSeries(series);
                } catch (e) { }
            }

            if (formattedData.length <= 0) {
                return;
            }

            const chartOptions = {
                layout: {
                    textColor: mode === 'light' ? 'black' : 'white',
                    background: { type: 'solid', color: 'transparent' },
                    border: '1px solid red',

                },
                grid: {
                    vertLines: {
                        visible: true,
                    },
                    horzLines: {
                        visible: true,
                    },
                }
            };
            // let chartData = getChartObject()
            let chartObjectData

            try {
                if (!chartObject) {
                    chartObjectData = createChart(areaChartContainerRef.current, chartOptions);
                } else {
                    chartObjectData = chartObject
                }
                setChartObject(chartObjectData);
                //chartData  = chartObjectData;
            } catch (error) {
                showErrorToast(error.message)
            }

            // let chartData = getChartObject()
            if (chartObjectData) {
                let seriesData = chartObjectData.addCandlestickSeries({});
                seriesData.setData(formattedData);
                chartObjectData.timeScale().fitContent();
                setSeries(seriesData)
                chartObjectData.subscribeCrosshairMove(param => {

                    // if (param.time) {
                    //     const data = param.seriesData.get(seriesData);
                    //     setAreaprice({ time: data.time, price: convertUSDDigit(data.high), isTime: true });
                    // } else {
                    //     setAreaprice({ time: '', price: 0, isTime: false });
                    // }

                    if (param.time) {
                        const dataIterator = param.seriesData.values();
                        const dataArray = [...dataIterator];
                        if (dataArray.length > 0) {
                            const data = dataArray[0];
                            setAreaprice({ time: data.time * 1000, price: convertTokentDigit(data.close), isTime: true });
                        } else {
                            setAreaprice({ time: '', price: 0, isTime: false });
                        }
                    } else {
                        setAreaprice({ time: '', price: 0, isTime: false });
                    }
                    setChartObject(chartObjectData);
                });

            }
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    };

    const getChartData = (chartType, periodType) => {
        try {
            if (chartType === 'liquidity') {
                getLiquidityChartData(periodType)
            } else if (chartType === 'volume') {
                getVolumeChartData(periodType)
            } else if (chartType === 'rate0') {
                getRate0HourlyChartData(periodType)
            } else if (chartType === 'rate1') {
                getRate1HourlyChartData(periodType)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const chartLayoutChange = () => {
        if (chartObject) {
            chartObject.applyOptions({
                layout: {
                    textColor: mode === 'light' ? 'black' : 'white'
                }
            })
        }
    }

    const handleClickChart = (type) => {
        try {
            if (type !== chartType) {
                setChartType(type)
                getChartData(type, periodType)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const handleTimePeriod = (type) => {
        try {
            if (type !== periodType) {
                setPeriodType(type)
                getChartData(chartType, type)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    // let chartOfLiquidityData

    // const getChartObject = () => {
    //     const chartOptions = {
    //         layout: {
    //             textColor: mode === 'light' ? 'black' : 'white',
    //             background: { type: 'solid', color: 'transparent' },
    //             border: '1px solid red',
    //         },
    //         grid: {
    //             vertLines: {
    //                 visible: true,
    //             },
    //             horzLines: {
    //                 visible: true,
    //             },
    //         }
    //     };
    //     if (!chartObject) {
    //         chartOfLiquidityData = createChart(areaChartContainerRef.current, chartOptions);
    //     } else {
    //         chartOfLiquidityData = chartObject
    //     }
    //     setChartObject(chartOfLiquidityData);
    //     return chartOfLiquidityData;
    // }


    const handleCopyText = (textToCopy, setCopiedAddress, setIsCopyIconShown) => {
        const fallbackCopyTextToClipboard = (text) => {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);

            setIsCopyIconShown(false);
            setCopiedAddress(textToCopy);
            setTimeout(() => {
                setCopiedAddress('');
                setIsCopyIconShown(true);
            }, 1000);
        };

        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setIsCopyIconShown(false);
                    setCopiedAddress(textToCopy);
                    setTimeout(() => {
                        setCopiedAddress('');
                        setIsCopyIconShown(true);
                    }, 1000);
                })
                .catch((error) => {
                    console.error('Error copying text:', error);
                    fallbackCopyTextToClipboard(textToCopy);
                });
        } else {
            fallbackCopyTextToClipboard(textToCopy);
        }
    };

    useEffect(() => {
        getPairAddressData()
        getPairTransactionData(type)
        getLiquidityChartData(periodType)
        // getChartObject()
        // getPairHourlyChartData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        chartLayoutChange()
        // eslint-disable-next-line
    }, [mode])

    return (
        <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#23242A]'} mb-10`}>
            <div className='p-4 lg:ml-[240px] lg:max-w-[100%]'>
                <div className="lg:max-w-[1440px] lg:mx-auto" >
                    <div className='flex justify-between items-center mt-4 w-full'>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'}  text-sm font-medium flex items-center`}><Link to='/pairs'>Pairs</Link> <ArrowForwardIosIcon sx={{ fontSize: '14px', marginLeft: '3px', marginRight: '1px' }} /> {allPairAddressData.pairName}</p>

                        {/* Search Option */}
                        <div className="relative lg:block md:block sm:hidden hidden w-80">
                            <SearchDropdown mode={mode} account={address} />
                        </div>
                        {/* Search Option */}

                    </div>

                    <div className='lg:flex md:flex justify-between items-center lg:mt-7 md:mt-7 mt-5 w-full'>
                        <div className='flex justify-start items-center text-white space-x-3'>
                            <img src={allPairAddressData.tokenIcon0 ? allPairAddressData.tokenIcon0 : "/asset/images/datagrid-icon.png"} alt="" className='h-8 z-0 rounded-full' />
                            <img src={allPairAddressData.tokenIcon1 ? allPairAddressData.tokenIcon1 : "/asset/images/datagrid-icon.png"} alt="" className='h-8 rounded-full' style={{ marginLeft: '-13px' }} />
                            <p className={`lg:text-3xl md:text-3xl sm:text-2xl text-2xl font-medium ${mode === 'light' ? 'text-black' : 'text-white'}`}><Link to={`/token/${allPairAddressData.tokenAddress0}`} className='hover:opacity-75'>{allPairAddressData.token0Symbol}</Link>-<Link to={`/token/${allPairAddressData.tokenAddress1}`} className='hover:opacity-75'>{allPairAddressData.token1Symbol}</Link> Pair</p>
                        </div>
                        <div className='flex justify-start items-center lg:mt-0 md:mt-0 sm:mt-5 mt-5'>
                            {/* <TurnedInNotIcon className='text-[#636368]' /> */}
                            <Link to={`${tradeUrl}/addLiquidity`} target='_blank' className={` bg-[#044e1f] hover:bg-green-800 text-white  text-[0.825rem]  px-3 py-2 rounded-lg cursor-pointer mx-2 font-semibold`}>+ Add Liquidity</Link>
                            <Link to={tradeUrl} target='_blank' className='text-white bg-green-700 hover:bg-green-600 text-[0.825rem] px-3 py-2 rounded-lg cursor-pointer font-semibold '>Trade</Link>
                        </div>
                    </div>
                    <div className='lg:flex md:flex justify-start mt-7'>
                        <div className={`flex justify-start items-center  border ${mode === 'light' ? 'hover:bg-gray-100' : 'border-[#40444F] hover:bg-[#2C2F36] '} rounded-lg px-3 py-2`}>
                            <img src={allPairAddressData.tokenIcon0 ? allPairAddressData.tokenIcon0 : "/asset/images/datagrid-icon.png"} alt="" className='h-5 rounded-full' />
                            <Link to={`/token/${allPairAddressData.tokenAddress0}`} className={`${mode === 'light' ? 'text-black' : 'text-white'} text-base font-medium mx-1`}>1 {allPairAddressData.token0Symbol} = {convertTokentDigit(allPairAddressData.token0Price)} {allPairAddressData.token1Symbol} ({convertUSDDigit(allPairAddressData.token0PriceUSD)}) </Link>
                        </div>
                        <div className={`flex  justify-start items-center   border  rounded-lg px-3 py-2 lg:mx-3 md:mx-3 lg:mt-0 md:mt-0 sm:mt-2 mt-2 ${mode === 'light' ? 'hover:bg-gray-100' : 'border-[#40444F]  hover:bg-[#2C2F36]'}`}>
                            <img src={allPairAddressData.tokenIcon1 ? allPairAddressData.tokenIcon1 : "/asset/images/datagrid-icon.png"} alt="" className='h-5 rounded-full' />
                            <Link to={`/token/${allPairAddressData.tokenAddress1}`} className={`${mode === 'light' ? 'text-black' : 'text-white'} text-base font-medium mx-1`}>1 {allPairAddressData.token1Symbol} = {convertTokentDigit(allPairAddressData.token1Price)} {allPairAddressData.token0Symbol} ({convertUSDDigit(allPairAddressData.token1PriceUSD)})  </Link>
                        </div>
                    </div>

                    <div className='flex items-center mt-10'>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} text-[1.125rem] font-medium`}>Pair Stats</p>
                        <Tooltip title={<h1 className='text-sm'>Derived USD values may be inaccurate without liquid stablecoin or BTC pairings.</h1>} placement='right'>
                            <ErrorOutlineIcon sx={{ color: mode === 'light' ? 'black' : 'white', fontSize: '16px', ml: 1 }} />
                        </Tooltip>
                    </div>

                    <Grid container spacing={1} mt={0.2} className='h-full'>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <div className={`block p-6 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] text-white border-[#40444F]'} border rounded-lg`}>
                                        <p className='mb-4 text-sm font-medium'>Total Liquidity</p>
                                        <div className='flex justify-between items-end'>
                                            <p className="mb-0  text-2xl font-medium ">{convertUSDDigit(allPairAddressData.reserveUSD)}</p>

                                            <p className={`mb-0  text-sm font-medium ${allPairAddressData.change24hoursLiquidityUSDPercentage < 0 ? 'text-red-500' : allPairAddressData.change24hoursLiquidityUSDPercentage > 0 ? 'text-green-500' : ''} `}>{getDigitSign(allPairAddressData.change24hoursLiquidityUSDPercentage)}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={`block p-6 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] text-white border-[#40444F]'} border rounded-lg`}>
                                        <p className='mb-4 text-sm font-medium'>Volume (24hrs)</p>
                                        <div className='flex justify-between items-end'>
                                            <p className="mb-0  text-2xl font-medium ">{convertUSDDigit(allPairAddressData.last24hoursVolumeUSD)}</p>
                                            <p className={`mb-0  text-sm font-medium ${allPairAddressData.change24hoursVolumeUSDPercentage < 0 ? 'text-red-500' : allPairAddressData.change24hoursVolumeUSDPercentage > 0 ? 'text-green-500' : ''} `}>{getDigitSign(allPairAddressData.change24hoursVolumeUSDPercentage)}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={`block p-6 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] text-white border-[#40444F]'} border rounded-lg`}>
                                        <p className='mb-4 text-sm font-medium'>Fees (24hrs)</p>
                                        <div className='flex justify-between items-end'>
                                            <p className="mb-0  text-2xl font-medium ">{convertUSDDigit(allPairAddressData.last24hoursPlatformFeesUSD)}</p>
                                            <p className={`mb-0  text-sm font-medium ${allPairAddressData.change24hoursPlatformFeesUSDPercentage < 0 ? 'text-red-500' : allPairAddressData.change24hoursPlatformFeesUSDPercentage > 0 ? 'text-green-500' : ''} `}>{getDigitSign(allPairAddressData.change24hoursPlatformFeesUSDPercentage)}</p>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={`block p-6  ${mode === 'light' ? 'bg-white text-black ' : 'bg-[#1F2026] text-white border-[#40444F]'} border  rounded-lg`}>
                                        <p className="mb-4 text-sm font-medium">Pooled Tokens</p>
                                        <Link to={`/token/${allPairAddressData.tokenAddress0}`} className='flex justify-start items-center hover:opacity-75 '>
                                            <img src={allPairAddressData.tokenIcon0 ? allPairAddressData.tokenIcon0 : "/asset/images/datagrid-icon.png"} alt="" className='h-6 rounded-full ' />
                                            <p className=" text-xl font-medium mx-2 ">{convertTokentDigit(allPairAddressData.reserve0)} {allPairAddressData.token0Symbol}</p>
                                        </Link>
                                        <Link to={`/token/${allPairAddressData.tokenAddress1}`} className='flex justify-start items-center mt-2 hover:opacity-75'>
                                            <img src={allPairAddressData.tokenIcon1 ? allPairAddressData.tokenIcon1 : "/asset/images/datagrid-icon.png"} alt="" className='h-6 rounded-full' />
                                            <p className=" text-xl font-medium mx-2 ">{convertTokentDigit(allPairAddressData.reserve1)} {allPairAddressData.token1Symbol}
                                            </p>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <div className={` justify-between p-2 lg:flex md:flex flex-none border rounded-md ${mode === 'light' ? 'text-black' : 'text-white border-[#40444f] bg-[#1F2026]'}`}>
                                <div className='space-x-2'>
                                    <button
                                        className={`${chartType === 'liquidity' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm`}
                                        onClick={() => handleClickChart('liquidity', periodType)}>Liquidity
                                    </button>
                                    <button
                                        className={`${chartType === 'volume' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm`}
                                        onClick={() => handleClickChart('volume', periodType)}>Volume
                                    </button>
                                    <button
                                        className={`${chartType === 'rate0' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm pairChartThirdButton`}
                                        onClick={() => handleClickChart('rate0', periodType)}>{allPairAddressData.token0Symbol}/{allPairAddressData.token1Symbol}
                                    </button>
                                    <button
                                        className={`${chartType === 'rate1' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm pairChartFirstButton`}
                                        onClick={() => handleClickChart('rate1', periodType)}>{allPairAddressData.token1Symbol}/{allPairAddressData.token0Symbol}
                                    </button>
                                </div>
                                <div className='space-x-2 md:mt-0 sm:mt-2 mt-2'>
                                    <button className={`${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} ${periodType === "week" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} border rounded-md  px-1.5 py-1 text-sm`} onClick={() => handleTimePeriod('week')}>1W</button>
                                    <button className={`${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} ${periodType === "month" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} border rounded-md  px-1.5 py-1 text-sm`} onClick={() => handleTimePeriod('month')}>1M</button>
                                    <button className={`${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} ${periodType === "all" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} border rounded-md  px-1.5 py-1 text-sm pairChartSecondButton`} onClick={() => handleTimePeriod('all')}>All</button>
                                </div>
                            </div>
                            <div className='relative'>
                                <div className={`absolute  z-50 w-full lg:h-[29.5rem] md:h-[29.5rem] sm:h-72 h-72 mt-2 tokenChartHeight rounded-lg  border ${mode === 'light' ? 'text-gray-800 bg-white' : ' border-[#40444f] bg-[#1F2026] text-white'} flex justify-center items-center ${loading ? 'visible' : 'hidden'}`}>
                                    <img src="/asset/images/navLogo.png" alt="logo" className="w-12 animate-bounce " />
                                </div>
                                <div ref={areaChartContainerRef} className={`absolute z-0 w-full lg:h-[29.5rem] md:h-[29.5rem] sm:h-72 h-72 mt-2 pairChartHeight rounded-lg border ${mode === 'light' ? 'text-gray-800' : ' border-[#40444f] bg-[#1F2026] text-white'}`}>
                                    {(chartType === 'rate0' || chartType === 'rate1') && (
                                        <div className='z-10' style={{ position: 'absolute', top: 20, left: 0, color: 'white' }}>
                                            <div className={` flex items-baseline gap-1 ${mode === 'dark' ? 'text-white' : 'text-black'} ms-5`}>
                                                <div className='text-xl'>
                                                    {areaPrice.price ? areaPrice.price : (chartType === 'rate1' ? convertTokentDigit(allPairAddressData.token0Price) : convertTokentDigit(allPairAddressData.token1Price))}

                                                </div>
                                                <div className='text-xs'>
                                                    {chartType === 'rate0' ? `${allPairAddressData.token0Symbol}/${allPairAddressData.token1Symbol}` : `${allPairAddressData.token1Symbol}/${allPairAddressData.token0Symbol}`}
                                                </div>
                                            </div>
                                            <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-5 text-xs font-medium`}>
                                                {areaPrice.time && formatDateWithTime(areaPrice.time)}
                                            </h1>
                                        </div>
                                    )}

                                </div>
                                <div className={`w-full lg:h-[29.5rem] md:h-[29.5rem] sm:h-72 h-72 z-0`}>
                                    {chartType === 'liquidity' && <AreaChartComponent dataKey={"Liquidity"} data={liquidityChartData} mode={mode} />}
                                    {chartType === 'volume' && <BarChartComponent data={volumeChartData} mode={mode} />}
                                </div>
                            </div>

                            {/* {volumeChart ? (
                                <div ref={areaChartContainerRef} className={`w-full lg:h-[33.5rem] md:h-[28rem] sm:h-72 h-72 mt-2  rounded-lg border ${mode === 'light' ? 'text-gray-800' : ' border-[#40444f] text-white'}`}>
                                </div>
                            ) : <></>}

                            {rate0Chart ? <div ref={areaChartContainerRef} className={`w-full lg:h-[33.5rem] md:h-[28rem] sm:h-72 h-72 mt-2  rounded-lg border ${mode === 'light' ? 'text-gray-800' : ' border-[#40444f] text-white'}`}>
                            </div> : <></>}
                            {rate1Chart ? <div ref={areaChartContainerRef} className={`w-full lg:h-[33.5rem] md:h-[28rem] sm:h-72 h-72 mt-2  rounded-lg border ${mode === 'light' ? 'text-gray-800' : ' border-[#40444f] text-white'}`}>
                            </div> : <></>} */}
                        </Grid>
                    </Grid>

                    <div>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} font-semibold mt-12 text-lg`}>Transactions</p>

                        <div className={`w-full ${mode === 'light' ? 'bg-white' : 'bg-[#1F2026] '} mt-5 borderRemove`}>
                            {pairTransactionDataLoader ? (
                                <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                    <div className="flex justify-center items-center w-full h-40">
                                        <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                    </div>
                                </div>
                            ) : (
                                <DataGrid
                                    sx={{
                                        color: mode === 'light' ? 'black' : 'white',
                                    }}
                                    rows={pairTransactionRows}
                                    columns={transactionsColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    getRowId={(row) => row.index}
                                    pageSizeOptions={[10]}
                                    disableRowSelectionOnClick
                                    autoHeight
                                    disableColumnResize={true}
                                    slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                                />
                            )}
                        </div>
                    </div>

                    <div className='mt-10'>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} text-lg font-semibold `}>Pair Information</p>

                        <div className={`lg:flex justify-between w-full font-semibold mt-5 p-5 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] border-[#40444F] text-white '}  border rounded-lg`}>
                            <div className='lg:flex justify-start '>
                                <div>
                                    <p className='text-[14px] font-normal'>Pair Name</p>
                                    <p className=' mt-2 text-base'>{allPairAddressData.pairName}</p>
                                </div>
                                <div>
                                    <p className='lg:mx-12 lg:mt-0 mt-5 text-[14px] font-normal'>Pair Address</p>
                                    <p className='lg:mx-12 md:mx-0 sm:mx-0 mx-0 mt-2 text-base'>
                                        {truncateAddress(allPairAddressData.pairAddress)}
                                        {allPairAddressData.pairAddress && (
                                            <>
                                                {copiedPairAddress !== allPairAddressData.pairAddress && isPairCopyIconShown && (
                                                    <ContentCopyIcon
                                                        sx={{ height: 15, cursor: 'pointer' }}
                                                        onClick={() => handleCopyText(allPairAddressData.pairAddress, setCopiedPairAddress, setIsPairCopyIconShown)}
                                                    />
                                                )}
                                                {copiedPairAddress === allPairAddressData.pairAddress && <CheckIcon sx={{ height: 15 }} />}
                                            </>
                                        )}
                                    </p>

                                </div>
                                <div>
                                    <p className='lg:mt-0 mt-5 text-[14px] font-normal'>{allPairAddressData.token0Symbol} Address</p>
                                    <p className='mt-2 text-base'>
                                        {truncateAddress(allPairAddressData.tokenAddress0)}
                                        {allPairAddressData.tokenAddress0 && (
                                            <>
                                                {copiedWiseAddress !== allPairAddressData.tokenAddress0 && isWiseCopyIconShown && (
                                                    <ContentCopyIcon
                                                        sx={{ height: 15, cursor: 'pointer' }}
                                                        onClick={() => handleCopyText(allPairAddressData.tokenAddress0, setCopiedWiseAddress, setIsWiseCopyIconShown)}
                                                    />
                                                )}
                                                {copiedWiseAddress === allPairAddressData.tokenAddress0 && <CheckIcon sx={{ height: 15 }} />}
                                            </>
                                        )}
                                    </p>

                                </div>
                                <div className='lg:mx-12 md:mx-0 sm::mx-0 mx-0 lg:mt-0 mt-5 '>
                                    <p className=' lg:mt-0 md:mt-4 sm:mt-4 mt-4 text-[14px] font-normal'>{allPairAddressData.token1Symbol} Address</p>
                                    <p className='mt-2 text-base'>
                                        {truncateAddress(allPairAddressData.tokenAddress1)}
                                        {allPairAddressData.tokenAddress1 && (
                                            <>
                                                {copiedEthAddress !== allPairAddressData.tokenAddress1 && isEthCopyIconShown && (
                                                    <ContentCopyIcon
                                                        sx={{ height: 15, cursor: 'pointer' }}
                                                        onClick={() => handleCopyText(allPairAddressData.tokenAddress1, setCopiedEthAddress, setIsEthCopyIconShown)}
                                                    />
                                                )}
                                                {copiedEthAddress === allPairAddressData.tokenAddress1 && <CheckIcon sx={{ height: 15 }} />}
                                            </>
                                        )}
                                    </p>

                                </div>
                            </div>
                            <div className='flex items-center'>
                                <Link to={`${platformUrl}/address/${allPairAddressData.pairAddress}`} target='_blank' className={`bg-[#044e1f] hover:bg-green-800 text-white text-[0.825rem] px-3 py-2 lg:mt-0 md:mt-5 sm:mt-5 mt-5 rounded-lg cursor-pointer`}>{platformButtonText} <CallMadeIcon sx={{ fontSize: '15px', transform: 'rotate(-4deg)' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PairDetail
