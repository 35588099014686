import axios from "axios"

const apiBaseUrl = process.env.REACT_APP_APIBASEURL

export const fetchEtherDetail = async () => {
    const response = await axios.get(`${apiBaseUrl}/dashboard/dashboard-native-token`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchDashboardTokenDetail = async () => {
    const response = await axios.get(`${apiBaseUrl}/dashboard/dashboard-token`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchDashboardPairDetail = async () => {
    const response = await axios.get(`${apiBaseUrl}/dashboard/dashboard-pair`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchDashboardTransactionDetail = async (actionType) => {
    const response = await axios.post(`${apiBaseUrl}/dashboard/dashboard-transactions`, {
        actionType
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchDashboardChartDetail = async () => {

    const response = await axios.get(`${apiBaseUrl}/dashboard/dashboard-chart/`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}