import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CustomNoRowsOverlay, convertTokentDigit, convertUSDDigit, getDigitSign, getMonthInWordWithDate, showErrorToast, timeAgo, truncateAddress, formatDateWithTime } from '../../allFunction/commonFunction';
import { fetchDailyTokenChartData, fetchHorlyTokenChartData, fetchTokenAddressDetail, fetchTokenChartDetails, fetchTokenPairDetail, fetchTokenTransactionDetail } from '../../api/token';
import AreaChartComponent from '../../component/AreaChart';
import BarChartComponent from '../../component/BarChart';
import SearchDropdown from '../../component/SearchDropdown';
import { pairColumns } from '../../constant/data';
import { defaultPageSize } from '../../constant/variable';

const TokenDetail = (props) => {

    const [isCopied, setIsCopied] = useState(false);
    const [isCopyIconShown, setIsCopyIconShown] = useState(true);
    const [allTokenAddressData, setAllTokenAddressData] = useState({})
    const [allTokenPairData, setAllTokenPairData] = useState([])
    const [allTokenTransactionData, setAllTokenTransactionData] = useState([])

    //loader State
    const [loading, setLoading] = useState(true);
    const [tokenPairDataLoader, setTokenPairDataLoader] = useState(true);
    const [tokenTransactionDataLoader, setTokenTransactionDataLoader] = useState(true);

    //Chart State
    const [type, setType] = useState('all')
    const [periodType, setPeriodType] = useState('week')
    const [chartType, setChartType] = useState('price')
    const [periodTime, setPeriodTime] = useState('hour')
    const [chartObject, setChartObject] = useState(null)
    const [series, setSeries] = useState(null);
    const [liquidityChartData, setLiquidityChartData] = useState([])
    const [areaPrice, setAreaprice] = useState({ price: '', time: '', isTime: false })
    const [volumeChartData, setVolumeChartData] = useState([])
    const [priceChartData, setPriceChartData] = useState([])

    const { mode } = props
    const lineChartContainerRef = useRef(null);
    const { address } = useParams()

    // const addLiquidityUrl = process.env.REACT_APP_ADDLIQUIDITYURL
    const tradeUrl = process.env.REACT_APP_TRADE_URL
    const platformUrl = process.env.REACT_APP_PLATFORM_URL
    const platformButtonText = process.env.REACT_APP_PLATFORM_BUTTON_TEXT

    const tokenPairRows = allTokenPairData.map((row, index) => ({ ...row, index: index + 1 }));
    // const tokenPairRows = allTokenPairData.map((row, index) => ({ ...row, id: index + 1, index: index + 1 }));

    const tokenTransactionsRows = allTokenTransactionData.map((row, index) => ({ ...row, index: index + 1 }));

    const transactionsColumns = [
        {
            field: 'transactionDescription',
            headerName: (
                <div className='space-x-2 ml-1'>
                    <button onClick={() => handleClick('all')} className={`${type === 'all' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>All</button>
                    <button onClick={() => handleClick('swap')} className={`${type === 'swap' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Swaps</button>
                    <button onClick={() => handleClick('mint')} className={`${type === 'mint' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Adds</button>
                    <button onClick={() => handleClick('burn')} className={`${type === 'burn' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Removes</button>
                </div>
            ),
            minWidth: 200,
            sortable: false,
            renderCell: (params) => {
                return (<div className="text-blue-500 hover:text-blue-600 ml-1"><Link to={`${platformUrl}/tx/${params.row.transactionHash}`} target='_blank'>{params.row.transactionDescription}</Link></div>)
            }
        },
        {
            field: 'totalUSD',
            headerAlign: 'right',
            headerName: <div className='hover:text-gray-400'>Total Value</div>,
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                const totalUSD = Number(params.row.totalUSD);
                let formattedTotalUSD = convertUSDDigit(totalUSD);

                // if (Math.abs(totalUSD) < 1000) {
                //     formattedTotalUSD = totalUSD.toFixed(2);
                // } else {
                //     formattedTotalUSD = totalUSD.toLocaleString(undefined, { maximumFractionDigits: 0 });
                // }

                return (
                    <div className='text-right'>
                        {formattedTotalUSD}
                    </div>
                );
            }
        },
        {
            field: 'displayAmount0',
            headerName: <div className='hover:text-gray-400 text-right'>Token Amount</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            renderCell: (params) => {
                const tokenUSD = Number(params.row.displayAmount0);
                let formattedTotalUSD = convertTokentDigit(tokenUSD);
                return (
                    <div className='text-right'>
                        {formattedTotalUSD} {params.row.token0Symbol}
                    </div>
                );
            }
        },
        {
            field: 'displayAmount1',
            headerName: <div className='hover:text-gray-400 text-right'>Token Amount</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            renderCell: (params) => {
                const tokenUSD = Number(params.row.displayAmount1);
                let formattedTotalUSD = convertTokentDigit(tokenUSD);
                return (
                    <div className='text-right'>
                        {formattedTotalUSD} {params.row.token1Symbol}
                    </div>
                );
            }
        },
        {
            field: 'accountAddress',
            headerName: <div className='hover:text-gray-400'>Account</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            sortable: false,
            renderCell: (params) => (<div className="text-blue-500 hover:text-blue-600 text-right"><Link to={`${platformUrl}/address/${params.row.accountAddress}`} target='_blank'>{truncateAddress(params.value)}</Link></div>)
        },
        {
            field: 'transactionDatetime',
            headerName: <div className='hover:text-gray-400 mr-1'>Time</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            renderCell: (params) => {
                return (
                    <div className='text-right mr-1'>
                        {timeAgo(params.value)}
                    </div>
                );
            }
        }

    ];

    const getTokenAddressData = async () => {
        try {
            const tokensData = await fetchTokenAddressDetail(address)
            if (!tokensData.success) {
                showErrorToast(tokensData.message)
            }
            setAllTokenAddressData(tokensData.data);
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const getTokenPairData = async () => {
        try {
            setTokenPairDataLoader(true);
            const tokensPairData = await fetchTokenPairDetail(address)
            if (!tokensPairData.success) {
                showErrorToast(tokensPairData.message)
            }
            setAllTokenPairData(tokensPairData.data);
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setTokenPairDataLoader(false);
        }
    }

    const getTokenTransactionData = async (actionType) => {
        try {
            setTokenTransactionDataLoader(true);
            const tokensTransactionData = await fetchTokenTransactionDetail(address, actionType)
            if (!tokensTransactionData.success) {
                showErrorToast(tokensTransactionData.message)
            }
            setAllTokenTransactionData(tokensTransactionData.data);
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setTokenTransactionDataLoader(false);
        }
    }

    const handleClick = async (actionType) => {
        try {
            setType(actionType);
            getTokenTransactionData(actionType)
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const handleCopyText = (textToCopy) => {
        try {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);

            setIsCopyIconShown(false);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
                setIsCopyIconShown(true);
            }, 1000);
        } catch (error) {
            showErrorToast(error.message)
        }

    };

    // let chartObjectData

    // const getChartObject = (chartOptions) => {
    //     
    //     try {
    //         if (!chartObject) {
    //             chartObjectData = createChart(lineChartContainerRef.current, chartOptions);
    //         } else {
    //             chartObjectData = chartObject
    //         }
    //         setChartObject(chartObjectData);
    //         return chartObjectData;
    //     } catch (error) {
    //         showErrorToast(error.message)
    //     }

    // }

    // Liquidity Chart Data
    const getLiquidityChartData = async (periodType) => {
        try {
            setLoading(true)
            const tokenChartData = await fetchTokenChartDetails(address, periodType);
            if (!tokenChartData.success) {
                showErrorToast(tokenChartData.message)
            }
            const formattedData = tokenChartData.data.map(entry => {
                const date = getMonthInWordWithDate(entry.dayStartDateTime);
                return { Liquidity: entry.totalLiquidityUSD, date: date };
            });
            if (formattedData.length <= 0) {
                return;
            }

            setLiquidityChartData(formattedData)

            if (series) {
                try {
                    chartObject.removeSeries(series);
                } catch (e) { }
            }

            // setChartObject(null)

        } catch (error) {
            showErrorToast(error.message)
            // chartOfLiquidity = null;
        } finally {
            setLoading(false)
        }
    };
    // Liquidity Chart Data

    const getVolumeChartData = async (type) => {

        try {
            setLoading(true)
            const tokenChartData = await fetchTokenChartDetails(address, type);
            if (!tokenChartData.success) {
                showErrorToast(tokenChartData.message)
            }
            const formattedData = tokenChartData.data.map(entry => {
                const date = getMonthInWordWithDate(entry.dayStartDateTime);
                return { Volume: entry.dailyVolumeUSD, date: date };
            });

            if (formattedData.length <= 0) {
                return;
            }

            setVolumeChartData(formattedData)

            if (series) {
                try {
                    chartObject.removeSeries(series);
                } catch (e) { }
            }

        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    };

    const getPriceChartData = async (type) => {
        try {
            setLoading(true)
            const tokenChartData = await fetchTokenChartDetails(address, type);
            if (!tokenChartData.success) {
                showErrorToast(tokenChartData.message)
            }
            const formattedData = tokenChartData.data.map(entry => {
                const date = getMonthInWordWithDate(entry.dayStartDateTime);
                return { Price: entry.priceUSD, date: date };
            });

            if (series) {
                try {
                    chartObject.removeSeries(series);
                } catch (e) {

                }
            }
            if (formattedData.length <= 0) {
                return;
            }

            setPriceChartData(formattedData)


        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    };

    const getDailyPriceChartData = async (periodType) => {

        try {
            setLoading(true)
            const pairData = await fetchDailyTokenChartData(address, periodType)
            if (!pairData.success) {
                showErrorToast(pairData.message)
            }
            const formattedData = pairData.data.map(entry => {
                return { open: entry.openPriceUSD, high: entry.highPriceUSD, low: entry.lowPriceUSD, close: entry.closePriceUSD, time: entry.dayStartTimeStamp / 1000 };
            });

            if (series) {
                try {
                    chartObject.removeSeries(series);
                } catch (e) { }
            }

            if (formattedData.length <= 0) {
                return;
            }

            const chartOptions = {
                layout: {
                    textColor: mode === 'light' ? 'black' : 'white',
                    background: { type: 'solid', color: 'transparent' },
                    border: '1px solid red',

                },
                grid: {
                    vertLines: {
                        color: 'rgba(197, 203, 206, 0.5)',
                    },
                    horzLines: {
                        color: 'rgba(197, 203, 206, 0.5)',
                    },
                },
            };

            // let chartData = getChartObject(chartOptions)
            let chartObjectData

            try {
                if (!chartObject) {
                    chartObjectData = createChart(lineChartContainerRef.current, chartOptions);
                } else {
                    chartObjectData = chartObject
                }
                setChartObject(chartObjectData);
                //chartData  = chartObjectData;
            } catch (error) {
                showErrorToast(error.message)
            }
            if (chartObjectData) {
                let seriesData = chartObjectData.addCandlestickSeries();
                seriesData.setData(formattedData);
                chartObjectData.timeScale().fitContent();
                setSeries(seriesData)
                chartObjectData.subscribeCrosshairMove(param => {
                    if (param.time) {
                        const dataIterator = param.seriesData.values();
                        const dataArray = [...dataIterator];
                        if (dataArray.length > 0) {
                            const data = dataArray[0];
                            setAreaprice({ time: data.time * 1000, price: convertUSDDigit(data.close), isTime: true });
                        } else {
                            setAreaprice({ time: '', price: 0, isTime: false });
                        }
                    } else {
                        setAreaprice({ time: '', price: 0, isTime: false });
                    }
                });
                setChartObject(chartObjectData);
            }
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    };

    const getHourlyPriceChartData = async (periodType) => {
        try {
            setLoading(true)
            const pairData = await fetchHorlyTokenChartData(address, periodType)
            if (!pairData.success) {
                showErrorToast(pairData.message)
            }
            const formattedData = pairData.data.map(entry => {
                return { open: entry.openPriceUSD, high: entry.highPriceUSD, low: entry.lowPriceUSD, close: entry.closePriceUSD, time: entry.hourStartUnix / 1000 };
            });

            if (series) {
                try {
                    chartObject.removeSeries(series);
                    // setSeries(null)
                } catch (e) { }
            }

            if (formattedData.length <= 0) {
                return;
            }

            const chartOptions = {
                layout: {
                    textColor: mode === 'light' ? 'black' : 'white',
                    background: { type: 'solid', color: 'transparent' },
                    border: '1px solid red',

                },
                grid: {
                    vertLines: {
                        visible: true,
                    },
                    horzLines: {
                        visible: true,
                    },
                }
            };

            // let chartData;
            let chartObjectData

            try {
                if (!chartObject) {
                    chartObjectData = createChart(lineChartContainerRef.current, chartOptions);
                } else {
                    chartObjectData = chartObject
                }
                setChartObject(chartObjectData);
                // chartData = chartObjectData;
            } catch (error) {
                showErrorToast(error.message)
            }
            if (chartObjectData) {

                let seriesData = chartObjectData.addCandlestickSeries({});
                seriesData.setData(formattedData);
                chartObjectData.timeScale().fitContent();
                setSeries(seriesData)
                chartObjectData.subscribeCrosshairMove(param => {

                    if (param.time) {
                        //const data = param.seriesData.get(seriesData);
                        const dataIterator = param.seriesData.values();
                        const dataArray = [...dataIterator];
                        if (dataArray.length > 0) {
                            const data = dataArray[0];
                            setAreaprice({ time: data.time * 1000, price: convertUSDDigit(data.close), isTime: true });
                        } else {
                            setAreaprice({ time: '', price: 0, isTime: false });
                        }
                    } else {
                        setAreaprice({ time: '', price: 0, isTime: false });
                    }
                    setChartObject(chartObjectData);

                });
            }
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLoading(false)
        }
    };

    // const getDailyPriceChartData = async (periodType) => {
    //     try {
    //         setLoading(true);
    //         const pairData = await fetchDailyTokenChartData(address, periodType);
    //         if (!pairData.success) {
    //             showErrorToast(pairData.message);
    //             return;
    //         }
    //         const formattedData = pairData.data.map(entry => {
    //             return { time: entry.dayStartTimeStamp / 1000, open: entry.openPriceUSD, high: entry.highPriceUSD, low: entry.lowPriceUSD, close: entry.closePriceUSD };
    //         });

    //         if (formattedData.length <= 0) {
    //             setLoading(false);
    //             return;
    //         }

    //         const chartOptions = {
    //             layout: {
    //                 textColor: mode === 'light' ? 'black' : 'white',
    //                 background: { type: 'solid', color: 'transparent' },
    //                 border: '1px solid red',
    //             },
    //             grid: {
    //                 vertLines: {
    //                     visible: true,
    //                 },
    //                 horzLines: {
    //                     visible: true,
    //                 },
    //             }
    //         };

    //         let chartData = getChartObject(chartOptions);
    //         if (chartData) {
    //             let seriesData = chartData.addCandlestickSeries();
    //             seriesData.setData(formattedData);
    //             chartData.timeScale().fitContent();
    //             setSeries(seriesData);
    //             chartObjectData.subscribeCrosshairMove(param => {
    //                 if (param.time) {
    //                     const data = param.seriesData.get(seriesData);
    //                     console.log(data);
    //                     if (data && data.time) {
    //                         setAreaprice({ time: data.time / 1000, price: convertUSDDigit(data.high), isTime: true });
    //                     } else {
    //                         setAreaprice({ time: '', price: 0, isTime: false });
    //                     }
    //                 }
    //             });
    //         }
    //     } catch (error) {
    //         showErrorToast(error.message);
    //     } finally {
    //         setLoading(false);
    //     }
    // };


    // const getHourlyPriceChartData = async (periodType) => {
    //     ;
    //     try {
    //         setLoading(true);
    //         const pairData = await fetchHorlyTokenChartData(address, periodType);
    //         if (!pairData.success) {
    //             showErrorToast(pairData.message);
    //             return;
    //         }
    //         const formattedData = pairData.data.map(entry => {
    //             return { time: entry.hourStartUnix / 1000, open: entry.openPriceUSD, high: entry.highPriceUSD, low: entry.lowPriceUSD, close: entry.closePriceUSD };
    //         });

    //         if (formattedData.length <= 0) {
    //             setLoading(false);
    //             return;
    //         }

    //         const chartOptions = {
    //             layout: {
    //                 textColor: mode === 'light' ? 'black' : 'white',
    //                 background: { type: 'solid', color: 'transparent' },
    //                 border: '1px solid red',
    //             },
    //             grid: {
    //                 vertLines: {
    //                     visible: true,
    //                 },
    //                 horzLines: {
    //                     visible: true,
    //                 },
    //             }
    //         };

    //         let chartData = getChartObject(chartOptions);
    //         if (chartData) {
    //             let seriesData = chartData.addCandlestickSeries({});
    //             seriesData.setData(formattedData);
    //             chartData.timeScale().fitContent();
    //             setSeries(seriesData);
    //             chartObjectData.subscribeCrosshairMove(param => {
    //                 ;
    //                 if (param.time) {
    //                     const data = param.seriesData.get(seriesData);
    //                     console.log(data);
    //                     if (data && data.time) {
    //                         setAreaprice({ time: data.time / 1000, price: convertUSDDigit(data.high), isTime: true });
    //                     } else {
    //                         setAreaprice({ time: '', price: 0, isTime: false });
    //                     }
    //                 }
    //             });
    //         }
    //     } catch (error) {
    //         showErrorToast(error.message);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const getChartData = (chartType, periodType, periodTime) => {

        try {
            if (chartType === 'liquidity') {
                getLiquidityChartData(periodType)
            } else if (chartType === 'volume') {
                getVolumeChartData(periodType)
            } else if (chartType === 'price') {
                handlePeriodTime(periodTime, periodType)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const chartLayoutChange = () => {
        if (chartObject) {
            chartObject.applyOptions({
                layout: {
                    textColor: mode === 'light' ? 'black' : 'white'
                }
            })
        }
    }

    const handlePeriodType = (type) => {
        try {
            if (type !== periodType) {
                setPeriodType(type)
                getChartData(chartType, type, periodTime)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const handleClickChart = (type, periodType) => {
        try {
            if (type !== chartType) {
                setChartType(type)
                getChartData(type, periodType, periodTime)
            }
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const handlePeriodTime = (time, type) => {

        try {

            setPeriodTime(time)
            if (time === 'daily') {
                getDailyPriceChartData(type)
            } else if (time === 'hour') {
                getHourlyPriceChartData(type)
            } else if (time === 'price') {
                getPriceChartData(type)
            }
            // getChartData(chartType, type)

        } catch (error) {
            showErrorToast(error.message)
        }
    }

    useEffect(() => {
        getTokenAddressData()
        getTokenPairData()
        getTokenTransactionData(type)
        getHourlyPriceChartData(periodType)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        chartLayoutChange()
        // eslint-disable-next-line
    }, [mode])

    return (
        <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#23242A]'} mb-10`}>
            <div className='p-4 lg:ml-[240px] lg:max-w-[100%]'>
                <div className="lg:max-w-[1440px] lg:mx-auto" >
                    <div className='flex justify-between items-center  mt-4  w-full'>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} text-sm font-medium flex items-center`}><Link to='/tokens'>Tokens</Link><ArrowForwardIosIcon sx={{ fontSize: '14px', marginLeft: '3px', marginRight: '1px' }} /> {allTokenAddressData.tokenSymbol}<span className={`font-normal ${mode === 'light' ? 'text-black' : 'text-gray-400'} ml-1`}><a className='cursor-pointer' href={`${platformUrl}/address/${allTokenAddressData.tokenAddress}`} target='_blank' rel='noreferrer'>({truncateAddress(allTokenAddressData.tokenAddress)})</a> </span> </p>
                        <div className="relative lg:block md:block sm:hidden hidden w-80">
                            <SearchDropdown mode={mode} />
                        </div>
                    </div>
                    <div className='lg:flex md:flex justify-between lg:mt-7 md:mt-7 mt-5 w-full'>
                        <div className={`flex items-end ${mode === 'light' ? 'text-black' : 'text-white'} space-x-3`}>
                            <div className='flex items-center space-x-3'>
                                <img src={allTokenAddressData.tokenIcon ? allTokenAddressData.tokenIcon : "/asset/images/datagrid-icon.png"} alt="" className='h-8 rounded-full' />
                                <p className='lg:text-3xl sm:text-2xl text-2xl font-medium'>{allTokenAddressData.tokenName} ({allTokenAddressData.tokenSymbol})</p>
                            </div>
                            <div className='flex items-baseline space-x-3'>
                                <p className='lg:block md:hidden sm:hidden hidden text-2xl font-medium'>{convertUSDDigit(allTokenAddressData.priceUSD)}</p>
                                <p className={`lg:block md:hidden sm:hidden hidden font-medium text-sm ${allTokenAddressData.change24hoursPricePercentage < 0 ? 'text-red-500' : allTokenAddressData.change24hoursPricePercentage > 0 ? 'text-green-500' : ''}`}>
                                    {getDigitSign(allTokenAddressData.change24hoursPricePercentage)}
                                </p>
                            </div>
                        </div>
                        <div className='flex items-center lg:mt-0 md:mt-0 sm:mt-5 mt-5'>
                            {/* <TurnedInNotIcon className='text-[#636368]' /> */}
                            <Link to={`${tradeUrl}/addLiquidity`} target='_blank' className='text-white text-[0.825rem] bg-[#044e1f] hover:bg-green-800 px-3 py-2 rounded-lg cursor-pointer mx-2 font-semibold' >+ Add Liquidity</Link>
                            <Link to={tradeUrl} target='_blank' className='text-white text-[0.825rem] bg-green-700 hover:bg-green-600 px-3 py-2 rounded-lg cursor-pointer font-semibold '>Trade</Link>
                        </div>
                    </div>
                    <div>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'}  mt-8 text-[1.125rem] font-medium`}>Token Stats</p>
                    </div>

                    <Grid container spacing={1} mt={0.2}>
                        <Grid item xs={12} md={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <div className={`block p-6 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] text-white border-[#40444F]'} border rounded-lg`}>
                                        <p className='mb-4 text-sm font-medium'>Total Liquidity</p>
                                        <div className='flex justify-between items-end'>
                                            <p className="mb-0 text-2xl font-medium ">  {convertUSDDigit(allTokenAddressData.totalLiquidityUSD)}</p>
                                            <p className={`mb-0 text-sm font-medium ${allTokenAddressData.change24hoursLiquidityUSDPercentage < 0 ? 'text-red-500' : allTokenAddressData.change24hoursLiquidityUSDPercentage > 0 ? 'text-green-500' : ''} `}>{getDigitSign(allTokenAddressData.change24hoursLiquidityUSDPercentage)}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={`block p-6 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] text-white border-[#40444F]'} border rounded-lg`}>
                                        <p className='mb-4 text-sm  font-medium'>Volume (24hrs)</p>
                                        <div className='flex justify-between items-end'>
                                            <p className="mb-0 text-2xl font-medium ">
                                                {convertUSDDigit(allTokenAddressData.last24hoursVolumeUSD)}

                                            </p>
                                            <p className={`mb-0 text-sm font-medium ${allTokenAddressData.change24hoursVolumeUSDPercentage < 0 ? 'text-red-500' : allTokenAddressData.change24hoursVolumeUSDPercentage > 0 ? 'text-green-500' : ''} `}>{getDigitSign(allTokenAddressData.change24hoursVolumeUSDPercentage)}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={`block p-6 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] text-white border-[#40444F]'} border rounded-lg`}>
                                        <p className='mb-4 text-sm  font-medium'>Transactions (24hrs)</p>
                                        <div className='flex justify-between items-end'>
                                            <p className="mb-0 text-2xl font-medium ">
                                                {convertTokentDigit(allTokenAddressData.last24hoursTransactions)}
                                            </p>
                                            <p className={`mb-0  text-sm font-medium ${allTokenAddressData.change24hoursTransactionsPercentage < 0 ? 'text-red-500' : allTokenAddressData.change24hoursTransactionsPercentage > 0 ? 'text-green-500' : ''} `}>{getDigitSign(allTokenAddressData.change24hoursTransactionsPercentage)}</p>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={8} >
                            <div className={`border ${mode === 'light' ? 'text-black bg-white' : 'text-white bg-[#1F2026] border-[#40444f]'} rounded-md p-2`}>
                                <div className={`lg:flex md:flex sm:flex flex-none justify-between`}>
                                    <div className='space-x-2'>
                                        <button onClick={() => handleClickChart('liquidity', periodType)} className={`${chartType === 'liquidity' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm`}>Liquidity</button>
                                        <button onClick={() => handleClickChart('volume', periodType)} className={`${chartType === 'volume' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm`}>Volume</button>
                                        <button
                                            onClick={() => handleClickChart('price', periodType)}
                                            // disabled={chartType === 'liquidity' || chartType === 'volume'}
                                            className={`${chartType === 'price' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${(chartType === 'liquidity' || chartType === 'volume') ? 'hover:bg-transparent' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f]'} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-3 py-1 text-sm`}
                                        >
                                            Price
                                        </button>
                                    </div>
                                    <div className='space-x-2 sm:mt-0 mt-2'>
                                        <button className={`${periodType === "week" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('week')}>1W</button>
                                        <button className={`${periodType === "month" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('month')}>1M</button>
                                        <button className={`${periodType === "all" ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodType('all')}>All</button>
                                    </div>
                                </div>

                                {chartType === 'price' && (
                                    <>
                                        <div className='mt-2 flex items-end'>
                                            <div className='space-x-2 '>
                                                <button className={`${periodTime === 'daily' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodTime('daily', periodType)}>D</button>
                                                <button className={`${periodTime === 'hour' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1.5 py-1 text-sm`} onClick={() => handlePeriodTime('hour', periodType)}>H</button>
                                                <button className={`${periodTime === 'price' ? mode === 'light' ? 'bg-gray-300' : 'bg-gray-600' : ''} ${mode === 'light' ? 'hover:bg-gray-200' : 'border-[#40444f] hover:bg-gray-600'} border rounded-md px-1 py-1 text-sm`} onClick={() => handlePeriodTime('price', periodType)}><TrendingUpIcon sx={{ fontSize: '15px' }} /></button>
                                            </div>
                                            <div className='flex items-end'>
                                                {(periodTime === 'daily' || periodTime === 'hour') && (
                                                    <>
                                                        <p className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-4 text-xl`} style={{ lineHeight: '22px' }}>{areaPrice.price ? areaPrice.price : convertUSDDigit(allTokenAddressData.priceUSD)} </p>
                                                        <p className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-2 text-xs font-medium`}>{areaPrice.time && formatDateWithTime(areaPrice.time)}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>

                                )}
                            </div>

                            {/* {loading ? <img src="/asset/images/navLogo.png" alt="logo" className="w-12 animate-bounce" /> : */}
                            <div className='relative'>
                                <div className={`absolute z-50 w-full ${chartType === 'price' ? 'lg:h-[17.5rem] md:h-[17.3rem] sm:h-72 h-72' : 'lg:h-[19.5rem] md:h-[19.5rem] sm:h-72 h-72'} mt-2 tokenChartHeight rounded-lg  border ${mode === 'light' ? 'text-gray-800 bg-white' : ' border-[#40444f] bg-[#1F2026] text-white'} flex justify-center items-center ${loading ? 'visible' : 'hidden'}`}>
                                    <img src="/asset/images/navLogo.png" alt="logo" className="w-12 animate-bounce " />
                                </div>
                                <div ref={lineChartContainerRef} className={`absolute z-0 w-full ${chartType === 'price' ? 'lg:h-[17.5rem] md:h-[17.3rem] sm:h-72 h-72' : 'lg:h-[19.5rem] md:h-[19.5rem] sm:h-72 h-72'} mt-2 tokenChartHeight rounded-lg  border ${mode === 'light' ? 'text-gray-800 bg-white' : ' border-[#40444f] bg-[#1F2026] text-white'} flex justify-center items-center `}>

                                </div>
                                <div className={`${chartType === 'price' ? 'lg:h-[17.5rem] md:h-[17.3rem] sm:h-72 h-72' : 'lg:h-[17.5rem] md:h-[17.3rem] sm:h-72 h-72'} z-0 `}>
                                    {chartType === 'liquidity' ? <AreaChartComponent dataKey={"Liquidity"} data={liquidityChartData} mode={mode} /> : ''}
                                    {chartType === 'volume' && <BarChartComponent data={volumeChartData} mode={mode} />}
                                    {chartType !== 'liquidity' && chartType !== 'volume' && periodTime === 'price' ? <AreaChartComponent dataKey={"Price"} data={priceChartData} mode={mode} /> : ''}
                                </div>
                            </div>


                        </Grid>
                    </Grid>

                    <div>
                        <div className=' font-semibold mt-12 flex justify-between items-center'>
                            <p className={`${mode === 'light' ? 'text-black' : 'text-white'} text-lg`}>Top Pairs</p>
                            {/* <div className="flex items-center">
                                <input id="default-checkbox" type="checkbox" value="" className="w-4 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded " />
                                <label htmlFor="default-checkbox" className={`ms-2 text-sm font-medium ${mode === 'light' ? 'text-black' : 'text-white'} hover:text-gray-400 cursor-pointer`}>Hide untracked pairs</label>
                                <Tooltip title="USD amounts">
                                    <HelpOutlineIcon sx={{ color: mode === 'light' ? 'black' : 'white', fontSize: '16px', ml: 1 }} />
                                </Tooltip>
                            </div> */}
                        </div>

                        <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} w-full  mt-5`}>
                            {tokenPairDataLoader ? (
                                <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                    <div className="flex justify-center items-center w-full h-40">
                                        <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                    </div>
                                </div>
                            ) : (
                                <DataGrid
                                    sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                    rows={tokenPairRows}
                                    columns={pairColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: defaultPageSize,
                                            },
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    getRowId={(row) => row.index}
                                    pageSizeOptions={[defaultPageSize]}
                                    disableRowSelectionOnClick
                                    autoHeight
                                    disableColumnResize={true}
                                    slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                                />
                            )}
                        </div>
                    </div>

                    <div>
                        <p className={`text-lg ${mode === 'light' ? 'text-black' : 'text-white'}  font-semibold mt-10`}>Transactions</p>
                        <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} w-full mt-5`}>
                            {tokenTransactionDataLoader ? (
                                <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                    <div className="flex justify-center items-center w-full h-40">
                                        <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                    </div>
                                </div>
                            ) : (
                                <DataGrid
                                    sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                    rows={tokenTransactionsRows}
                                    columns={transactionsColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    showColumnVerticalBorder={true}
                                    getRowId={(row) => row.index}
                                    pageSizeOptions={[10]}
                                    disableRowSelectionOnClick
                                    autoHeight
                                    disableColumnResize={true}
                                    slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                                />
                            )}
                            {/* {allTokenTransactionData.length === 0 && (
                                <div className="text-center text-gray-500 mt-5">No transactions available for the selected type.</div>
                            )} */}
                        </div>
                    </div>

                    <div className='mt-10'>
                        <p className={`text-lg ${mode === 'light' ? 'text-black' : 'text-white'}  font-semibold `}>Token Information</p>

                        <div className={`lg:flex justify-between w-full font-semibold mt-5 p-5 ${mode === 'light' ? 'bg-white text-black' : 'bg-[#1F2026] border-[#40444F] text-white '}  border rounded-lg`}>
                            <div className='lg:flex justify-start '>
                                <div>
                                    <p className='text-[14px] font-normal'>Symbol</p>
                                    <p className='text-base font-semibold mt-2'>{allTokenAddressData.tokenSymbol}</p>
                                </div>
                                <div>
                                    <p className=' lg:mx-12 lg:mt-0 mt-5 text-[14px] font-normal'>Name</p>
                                    <p className='lg:mx-12 text-base font-semibold mt-2'>{allTokenAddressData.tokenName}</p>
                                </div>
                                <div>
                                    <p className=' lg:mt-0 mt-5 text-[14px] font-normal'>Address</p>
                                    <p className='text-base font-semibold mt-2'>
                                        {truncateAddress(allTokenAddressData.tokenAddress)}
                                        {allTokenAddressData.tokenAddress && (
                                            <>
                                                {isCopyIconShown && (
                                                    <ContentCopyIcon sx={{ height: 15, cursor: 'pointer' }} onClick={() => handleCopyText(allTokenAddressData.tokenAddress)} />
                                                )}
                                                {isCopied && <CheckIcon sx={{ height: 15 }} />}
                                            </>
                                        )}
                                    </p>

                                </div>
                            </div>
                            <div className='flex items-center'>
                                <Link to={`${platformUrl}/address/${allTokenAddressData.tokenAddress}`} target='_blank' className={` text-[0.825rem] bg-[#044e1f] hover:bg-green-800  text-white px-3 py-2 lg:mt-0 md:mt-5 sm:mt-5 mt-5 rounded-lg cursor-pointer`}>{platformButtonText} <CallMadeIcon sx={{ fontSize: '15px', transform: 'rotate(-4deg)' }} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default TokenDetail
