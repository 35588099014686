import axios from "axios"

const apiBaseUrl = process.env.REACT_APP_APIBASEURL

export const fetchTokenDetail = async () => {
    const response = await axios.get(`${apiBaseUrl}/token/token-list`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchTokenAddressDetail = async (tokenAddress) => {
    const response = await axios.get(`${apiBaseUrl}/token/token-details/${tokenAddress}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchTokenPairDetail = async (tokenAddress) => {
    const response = await axios.get(`${apiBaseUrl}/token/token-pairs/${tokenAddress}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchTokenTransactionDetail = async (tokenAddress, actionType) => {

    const response = await axios.post(`${apiBaseUrl}/token/token-transactions/${tokenAddress}`, {
        actionType
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchTokenChartDetails = async (tokenAddress, periodType) => {

    const response = await axios.post(`${apiBaseUrl}/token/token-chart/${tokenAddress}`, {
        periodType
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchHorlyTokenChartData = async (tokenAddress, periodType) => {
    const response = await axios.post(`${apiBaseUrl}/token/token-hourly-chart/${tokenAddress}`, {
        periodType,
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}
export const fetchDailyTokenChartData = async (tokenAddress, periodType) => {
    const response = await axios.post(`${apiBaseUrl}/token/token-daily-chart/${tokenAddress}`, {
        periodType,
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}
