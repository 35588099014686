import React from 'react'
import { ResponsiveContainer, XAxis, YAxis, Bar, BarChart, Tooltip } from 'recharts';
import { toK } from '../allFunction/commonFunction';
import { formattedNum } from '../allFunction/commonFunction';

const BarChartComponent = ({ mode, data }) => {



    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={150}
                height={40}
                data={data}
                barGap={0}
                barCategoryGap={1}
                margin={{
                    top: 20,
                    right: 0,
                    left: 3,
                    bottom: 5,
                }}>
                <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={false}
                    minTickGap={80}
                    tickMargin={10}
                    tick={{
                        fill: mode === 'light' ? 'black' : 'white',
                        fontSize: '12px'
                    }}

                />
                <YAxis
                    orientation="right"
                    type="number"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(tick) => '$' + toK(tick)}
                    tick={{
                        fill: mode === 'light' ? 'black' : 'white',
                        fontSize: '12px'
                    }}

                />
                <Tooltip
                    cursor={{ fill: 'transparent', opacity: 0.1 }}
                    formatter={(val) => formattedNum(val, true)}
                    contentStyle={{
                        padding: '10px 14px',
                        borderRadius: 10,
                        borderColor: 'black',
                        color: 'black',
                    }} />
                <Bar dataKey="Volume" fill="#27AE60" />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarChartComponent