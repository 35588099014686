import axios from "axios"

const apiBaseUrl = process.env.REACT_APP_APIBASEURL

export const searchTokenAndPair = async (params) => {
    const response = await axios.get(`${apiBaseUrl}/dashboard/search-pair-token`,
        {
            params,
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}