import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { pairColumns, tokenColumns } from '../../constant/data';
import { createChart } from 'lightweight-charts';
import { CustomNoRowsOverlay, convertTokentDigit, convertUSDDigit, formatDate, getDigitSign, getMonthInWordWithDateAndYear, showErrorToast, timeAgo, truncateAddress } from '../../allFunction/commonFunction';
import { fetchDashboardChartDetail, fetchDashboardPairDetail, fetchDashboardTokenDetail, fetchDashboardTransactionDetail, fetchEtherDetail } from '../../api/dashboard';
import { defaultPageSize } from '../../constant/variable';
import SearchDropdown from '../../component/SearchDropdown';
import { Grid } from '@mui/material';

const Overview = (props) => {

    const [tokenEtherData, setTokenEtherData] = useState([])
    const [allTokenData, setAllTokenData] = useState([]);
    const [allPairData, setAllPairData] = useState([]);
    const [allTransactionData, setAllTransactionData] = useState([]);
    //loader state
    const [tokenDataLoader, setTokenDataLoader] = useState(true);
    const [pairDataLoader, setPairDataLoader] = useState(true);
    const [transactionDataLoader, setTransactionDataLoader] = useState(true);
    const [areaChartLoader, setAreaChartLoader] = useState(true)
    const [lineChartLoader, setLineChartLoader] = useState(true)

    const [areaPrice, setAreaprice] = useState({ price: '', time: '', isTime: false })
    const [histogramPrice, setHistogramPrice] = useState({ price: '', time: '', isTime: false })
    const [type, setType] = useState('all')
    const [areaChartObject, setAreaChartObject] = useState(null);
    const [chartObject, setChartObject] = useState(null);
    const [areaSeries, setAreaSeries] = useState(null);
    const [histogramSeries, setHistogramSeries] = useState(null);

    const { mode } = props
    const platformUrl = process.env.REACT_APP_PLATFORM_URL

    const areaChartContainerRef = useRef(null);
    const lineChartContainerRef = useRef(null);

    const tokenRows = allTokenData.map((row, index) => ({ ...row, index: index + 1 }));
    const pairRows = allPairData.map((row, index) => ({ ...row, index: index + 1 }));
    // const pairRows = allPairData.map((row, index) => ({ ...row, id: index + 1, index: index + 1 }));
    const transactionRows = allTransactionData.map((row, index) => ({ ...row, index: index + 1 }));

    const transactionsColumns = [
        {
            field: 'transactionDescription',
            headerName: (
                <div className='space-x-2 ml-1'>
                    <button onClick={() => handleClick('all')} className={`${type === 'all' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>All</button>
                    <button onClick={() => handleClick('swap')} className={`${type === 'swap' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Swaps</button>
                    <button onClick={() => handleClick('mint')} className={`${type === 'mint' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Adds</button>
                    <button onClick={() => handleClick('burn')} className={`${type === 'burn' ? (mode === 'light' ? 'text-black' : 'text-white') : 'text-gray-500'}`}>Removes</button>
                </div>
            ),
            minWidth: 200,
            sortable: false,
            disableRowSelectionOnClick: true,
            renderCell: (params) => {
                return (<div className="text-blue-500 hover:text-blue-600 ml-1"><Link to={`${platformUrl}/tx/${params.row.transactionHash}`} target='_blank'>{params.row.transactionDescription}</Link></div>)
            }
        },
        {
            field: 'totalUSD',
            headerAlign: 'right',
            headerName: <div className='hover:text-gray-400'>Total Value</div>,
            minWidth: 160,
            flex: 1,
            renderCell: (params) => {
                const totalUSD = Number(params.row.totalUSD);
                let formattedTotalUSD = convertUSDDigit(totalUSD);
                return (
                    <div className='text-right'>
                        {formattedTotalUSD}
                    </div>
                );
            }
        },
        {
            field: 'displayAmount0',
            headerName: <div className='hover:text-gray-400 text-right'>Token Amount</div>,
            minWidth: 160,
            flex: 1,

            headerAlign: 'right',
            renderCell: (params) => {
                const displayAmount0 = Number(params.row.displayAmount0);
                let formatteddisplayAmount0 = convertTokentDigit(displayAmount0);

                return (
                    <div className='text-right'>
                        {formatteddisplayAmount0} {params.row.token0Symbol}
                    </div>
                );
            }
        },
        {
            field: 'displayAmount1',
            headerName: <div className='hover:text-gray-400 text-right'>Token Amount</div>,
            minWidth: 160,
            flex: 1,

            headerAlign: 'right',
            renderCell: (params) => {
                const displayAmount1 = Number(params.row.displayAmount1);
                let formatteddisplayAmount1 = convertTokentDigit(displayAmount1);


                return (
                    <div className='text-right'>
                        {formatteddisplayAmount1} {params.row.token1Symbol}
                    </div>
                );
            }
        },
        {
            field: 'accountAddress',
            headerName: <div className='hover:text-gray-400'>Account</div>,
            minWidth: 160,
            flex: 1,
            headerAlign: 'right',
            sortable: false,
            renderCell: (params) => (<div className="text-blue-500 hover:text-blue-600 text-right"><Link to={`${platformUrl}/address/${params.row.accountAddress}`} target='_blank'>{truncateAddress(params.value)}</Link></div>)
        },
        {
            field: 'transactionDatetime',
            headerName: <div className='hover:text-gray-400 mr-1'>Time</div>,
            minWidth: 160,
            flex: 1,

            headerAlign: 'right',
            renderCell: (params) => {
                return (
                    <div className='text-right mr-1'>
                        {timeAgo(params.value)}
                    </div>
                );
            }
        }

    ];

    const handleClick = async (transactionType) => {
        try {
            setType(transactionType);
            await getTransactionData(transactionType);
        } catch (error) {
            showErrorToast(error.message)
        }
    };

    const getTokenEtherData = async () => {
        try {
            const tokensData = await fetchEtherDetail();
            if (!tokensData.success) {
                showErrorToast(tokensData.message)
            }
            setTokenEtherData(tokensData.data);
        } catch (error) {
            showErrorToast(error.message)
        }
    }

    const getTokenData = async () => {
        try {
            setTokenDataLoader(true);
            const tokensData = await fetchDashboardTokenDetail();
            if (!tokensData.success) {
                showErrorToast(tokensData.message)
            }
            setAllTokenData(tokensData.data);
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setTokenDataLoader(false);

        }
    }

    const getPairData = async () => {
        try {
            setPairDataLoader(true);
            const tokensData = await fetchDashboardPairDetail()
            if (!tokensData.success) {
                showErrorToast(tokensData.message)
            }
            setAllPairData(tokensData.data);
        } catch (error) {
            showErrorToast(error.message)

        } finally {
            setPairDataLoader(false);
        }
    }

    const getTransactionData = async (transactionType) => {

        try {
            setTransactionDataLoader(true);
            const tokensData = await fetchDashboardTransactionDetail(transactionType)
            if (!tokensData.success) {
                showErrorToast(tokensData.message)
            }
            setAllTransactionData(tokensData.data);
        } catch (error) {
            showErrorToast(error.message)
            setAllTransactionData([])
        } finally {
            setTransactionDataLoader(false);

        }
    }

    let chartOfHistogramData
    let chartOfAreaData

    const getAreaChartData = async () => {
        try {
            setAreaChartLoader(true)
            const data = await fetchDashboardChartDetail();
            const formattedData = data.data.map(entry => {
                const date = formatDate(entry.dayStartDateTime);
                return { value: entry.totalLiquidityUSD, time: date };
            });

            // if (!areaChart) {
            //     const chartOptions = {
            //         layout: {
            //             textColor: mode === 'dark' ? 'white' : 'black',
            //             background: { type: 'solid', color: 'transparent' }
            //         },
            //         XAxis: [{
            //             ticks: {
            //                 fontColor: 'green'
            //             },
            //         }],

            //         crosshair: {
            //             // hide the horizontal crosshair line
            //             horzLine: {
            //                 visible: false,
            //                 labelVisible: false,
            //             },
            //             // hide the vertical crosshair label
            //             vertLine: {
            //                 visible: false,
            //                 labelVisible: false,

            //             },
            //         },
            //         grid: {
            //             vertLines: {
            //                 visible: false,
            //             },
            //             horzLines: {
            //                 visible: false,
            //             },
            //         },
            //     };

            //     areaChart = createChart(areaChartContainerRef.current, chartOptions);
            //     const areaSeries = areaChart.addAreaSeries({
            //         // lineColor: '#2962FF',
            //         // topColor: '#2962FF',
            //         // bottomColor: 'rgba(41, 98, 255, 0.28)'
            //     });
            //     areaSeries.setData(formattedData);


            //     areaChart.timeScale().fitContent();

            //     return () => {
            //         areaChart.remove();
            //     };
            // }

            // if (areaSeries) {
            //     areaChartObject.removeSeries(areaSeries);
            // }

            if (areaSeries) {
                try {
                    chartObject.removeSeries(areaSeries);
                } catch (e) { }
            }

            let chartData = getChartObject()
            if (chartData) {
                let seriesData = chartData.addAreaSeries({});
                seriesData.setData(formattedData);
                chartData.timeScale().fitContent();
                setAreaSeries(seriesData)
                chartOfAreaData.subscribeCrosshairMove(param => {
                    if (param.time) {
                        const data = param.seriesData.get(seriesData);
                        setAreaprice({ time: data.time, price: convertUSDDigit(data.value), isTime: true });
                    } else {
                        setAreaprice({ time: '', price: 0, isTime: false });
                    }
                    // if (param.time) {
                    //     //const data = param.seriesData.get(seriesData);
                    //     const dataIterator = param.seriesData.values();
                    //     const dataArray = [...dataIterator];
                    //     if (dataArray.length > 0) {
                    //         const data = dataArray[0];
                    //         setAreaprice({ time: data.time, price: convertUSDDigit(data.high), isTime: true });
                    //     } else {
                    //         setAreaprice({ time: '', price: 0, isTime: false });
                    //     }
                    // } else {
                    //     setAreaprice({ time: '', price: 0, isTime: false });
                    // }

                });
            }
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setAreaChartLoader(false)
        }
    }

    const getHistogramChartData = async () => {
        try {
            setLineChartLoader(true)
            const data = await fetchDashboardChartDetail();
            const formattedData = data.data.map(entry => {
                const date = formatDate(entry.dayStartDateTime);
                return { value: entry.dailyVolumeUSD, time: date };
            });

            // if (!histogramChart) {
            //     const chartOptions = {
            //         layout: {
            //             textColor: mode === 'light' ? 'black' : 'white',
            //             background: { type: 'solid', color: 'transparent' }
            //         },
            //         crosshair: {
            //             // hide the horizontal crosshair line
            //             horzLine: {
            //                 visible: false,
            //                 labelVisible: false,
            //             },
            //             // hide the vertical crosshair label
            //             vertLine: {
            //                 visible: false,
            //                 labelVisible: false,

            //             },
            //         },
            //         grid: {
            //             vertLines: {
            //                 visible: false,
            //             },
            //             horzLines: {
            //                 visible: false,
            //             },
            //         },
            //     };

            //     histogramChart = createChart(lineChartContainerRef.current, chartOptions);
            //     const areaSeries = histogramChart.addHistogramSeries({
            //         color: '#27AE60'
            //     });

            //     histogramChart.subscribeCrosshairMove(param => {
            //         if (param.time) {
            //             const data = param.seriesData.get(areaSeries)
            //             setHistogramPrice({ time: data.time, price: convertUSDDigit(data.value), isTime: true })
            //         }
            //         else {
            //             setHistogramPrice({ time: '', price: 0, isTime: false });
            //         }
            //     })

            //     areaSeries.setData(formattedData);
            //     histogramChart.timeScale().fitContent();

            //     return () => {
            //         histogramChart.remove();
            //     };
            // }

            // if (histogramSeries) {
            //     chartObject.removeSeries(histogramSeries);
            // }

            if (histogramSeries) {
                try {
                    chartObject.removeSeries(histogramSeries);
                } catch (e) { }
            }

            let chartData = getHistogramObject()
            if (chartData) {
                let seriesData = chartData.addHistogramSeries({
                    color: '#27AE60',
                });

                seriesData.setData(formattedData);
                chartData.timeScale().fitContent();
                setHistogramSeries(seriesData)
                chartOfHistogramData.subscribeCrosshairMove(param => {
                    if (param.time) {
                        const data = param.seriesData.get(seriesData);
                        setHistogramPrice({ time: data.time, price: convertUSDDigit(data.value), isTime: true });
                    } else {
                        setHistogramPrice({ time: '', price: 0, isTime: false });
                    }
                    // if (param.time) {
                    //     //const data = param.seriesData.get(seriesData);
                    //     const dataIterator = param.seriesData.values();
                    //     const dataArray = [...dataIterator];
                    //     if (dataArray.length > 0) {
                    //         const data = dataArray[0];
                    //         setHistogramPrice({ time: data.time, price: convertUSDDigit(data.high), isTime: true });
                    //     } else {
                    //         setHistogramPrice({ time: '', price: 0, isTime: false });
                    //     }
                    // } else {
                    //     setHistogramPrice({ time: '', price: 0, isTime: false });
                    // }
                });
            }
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setLineChartLoader(false)
        }
    }

    const getHistogramObject = () => {
        const chartOptions = {
            layout: {
                textColor: mode === 'light' ? 'black' : 'white',
                background: { type: 'solid', color: 'transparent' }
            },
            crosshair: {
                // hide the horizontal crosshair line
                horzLine: {
                    visible: false,
                    labelVisible: false,
                },
                // hide the vertical crosshair label
                vertLine: {
                    visible: false,
                    labelVisible: false,

                },
            },
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            },
        };
        if (!chartObject) {
            chartOfHistogramData = createChart(lineChartContainerRef.current, chartOptions);
        } else {
            chartOfHistogramData = chartObject
        }

        setChartObject(chartOfHistogramData);
        return chartOfHistogramData;
    }

    const getChartObject = () => {
        const chartOptions = {
            layout: {
                textColor: mode === 'light' ? 'black' : 'white',
                background: { type: 'solid', color: 'transparent' }
            },
            XAxis: [{
                ticks: {
                    fontColor: 'green'
                },
            }],
            // timeScale: {
            //     borderColor: '#979797',
            // },
            // rightPriceScale: {
            //     borderColor: '#979797',
            // },
            crosshair: {
                // hide the horizontal crosshair line
                horzLine: {
                    visible: false,
                    labelVisible: false,
                },
                // hide the vertical crosshair label
                vertLine: {
                    visible: false,
                    labelVisible: false,

                },
            },
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            },
        };
        if (areaChartObject) {
            // areaChartObject.setOptions(chartOptions);
            chartOfAreaData = areaChartObject;
        } else {
            chartOfAreaData = createChart(areaChartContainerRef.current, chartOptions);
        }
        // if (!areaChartObject) {
        //     chartOfAreaData = createChart(areaChartContainerRef.current, chartOptions);
        // } else {
        //     chartOfAreaData = areaChartObject
        // }
        setAreaChartObject(chartOfAreaData);
        return chartOfAreaData;
    }

    const chartLayoutChange = () => {
        if (areaChartObject) {
            areaChartObject.applyOptions({
                layout: {
                    textColor: mode === 'light' ? 'black' : 'white'
                },
                // timeScale: {
                //     borderColor: mode === 'light' ? 'red' : 'green',
                // },
                // rightPriceScale: {
                //     borderColor: mode === 'light' ? 'red' : 'green',
                // },
            })
        }
        if (chartObject) {
            chartObject.applyOptions({
                layout: {
                    textColor: mode === 'light' ? 'black' : 'white'
                }
            })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await getTokenEtherData();
            await getAreaChartData();
            await getHistogramChartData();
            await getTokenData();
            await getPairData();
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        chartLayoutChange()
        // eslint-disable-next-line
    }, [mode])

    useEffect(() => {
        getTransactionData(type)
        // eslint-disable-next-line
    }, [type])

    return (
        <div className='relative flex flex-col'>
            <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#23242A]'} mb-16`}>
                <div className='p-4 lg:ml-[240px] lg:max-w-[100%]'>
                    <div className="lg:max-w-[1440px] lg:mx-auto" >
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} text-2xl font-semibold mt-5`}>Virothium Analytics</p>
                        <label htmlFor="voice-search" className="sr-only">Search</label>
                        <div className="relative w-full mt-5">
                            <SearchDropdown mode={mode} placeholder={'Search Virothium pairs and tokens...'} />
                        </div>

                        <div className={`${mode === 'light' ? 'text-black' : 'text-[#F9F9F9]'} lg:flex md:flex justify-start font-medium lg:space-x-5 md:space-x-5 lg:mx-2 md:mx-2 mt-7 mb-4`}>
                            <p className='text-sm lg:mt-0 sm:mt-1 mt-1'>{tokenEtherData.tokenSymbol} Price: {convertUSDDigit(tokenEtherData.nativeTokenUSDPrice)} </p>
                            <p className='text-sm lg:mt-0 sm:mt-1 mt-1'> Transactions (24H): {tokenEtherData.last24hoursTokenTransactions ? tokenEtherData.last24hoursTokenTransactions : 0}</p>
                            <p className='text-sm lg:mt-0 sm:mt-1 mt-1'> Pairs: {tokenEtherData.tokenPairCount ? tokenEtherData.tokenPairCount : 0}</p>
                            <p className='text-sm lg:mt-0 sm:mt-1 mt-1'> Fees (24H): {convertUSDDigit(tokenEtherData.last24hourstokenFeesUSD)}</p>
                        </div>

                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ position: 'relative' }}>
                                <div className={`border rounded-lg ${mode === 'light' ? 'bg-white' : ' border-[#40444f] bg-[#1F2026]'} my-2 h-20 p-3 `} >
                                    <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-1 text-base font-normal`}>Liquidity</h1>
                                    <div className='flex items-baseline'>
                                        <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-1 text-xl`}>{areaPrice.price ? areaPrice.price : convertUSDDigit(tokenEtherData.tokenTotalLiquidityUSD)}{!areaPrice.price && <span className={`ms-2 ${tokenEtherData.change24hoursVolumeUSDPercentage < 0 ? 'text-red-500' : tokenEtherData.change24hoursVolumeUSDPercentage > 0 ? 'text-green-500' : mode === 'light' ? 'text-black' : ' text-white'} text-sm font-medium`}>
                                            {getDigitSign(tokenEtherData.change24hoursVolumeUSDPercentage)}</span>}</h1>
                                        <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-2 text-xs font-medium`}>{areaPrice.time && getMonthInWordWithDateAndYear(areaPrice.time)}</h1>
                                    </div>
                                </div>
                                <div ref={areaChartContainerRef} className={`rounded-lg border ${mode === 'light' ? 'bg-white' : ' border-[#40444f] bg-[#1F2026]'}  lg:h-[25rem] md:h-[25rem] sm:h-72 h-72`}></div>
                                <div className={`absolute top-[112px] lg:w-[98%] md:w-[97%] sm:w-[96%] w-[96%] z-20 lg:h-[25rem] md:h-[25rem] sm:h-72 h-72 rounded-lg border ${mode === 'light' ? 'text-gray-800 bg-white' : 'border-[#40444f] bg-[#1F2026] text-white'} flex justify-center items-center ${areaChartLoader ? 'visible' : 'hidden'}`} style={{
                                    transition: "all .50s",
                                }}>
                                    <img src="/asset/images/navLogo.png" alt="logo" className="w-12 animate-bounce " />
                                </div>

                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ position: 'relative' }}>
                                <div className={`border rounded-lg ${mode === 'light' ? 'bg-white' : ' border-[#40444f] bg-[#1F2026]'} my-2 h-20 p-3`} >
                                    <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-1 text-base font-normal`}>Volume (24hrs)</h1>
                                    <div className='flex items-baseline'>
                                        <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-1 text-xl`}>{histogramPrice.price ? histogramPrice.price : convertUSDDigit(tokenEtherData.last24hoursTokenVolumeUSD)}{!histogramPrice.price && <span className={`ms-2 ${tokenEtherData.change24hoursVolumeUSDPercentage < 0 ? 'text-red-500' : tokenEtherData.change24hoursVolumeUSDPercentage > 0 ? 'text-green-500' : mode === 'light' ? 'text-black' : ' text-white'} text-sm font-medium`}>
                                            {getDigitSign(tokenEtherData.change24hoursVolumeUSDPercentage)}</span>}</h1>
                                        <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-2 text-xs font-medium`}>{histogramPrice.time && getMonthInWordWithDateAndYear(histogramPrice.time)}</h1>
                                    </div>
                                </div>
                                <div ref={lineChartContainerRef} className={` rounded-lg border ${mode === 'light' ? 'bg-white' : ' border-[#40444f] bg-[#1F2026]'}  lg:h-[25rem] md:h-[25rem] sm:h-72 h-72`}></div>
                                <div className={`absolute top-[112px]  lg:w-[98%] md:w-[97%] sm:w-[96%] w-[96%] z-20  lg:h-[25rem] md:h-[25rem] sm:h-72 h-72  rounded-lg border ${mode === 'light' ? 'text-gray-800 bg-white' : 'border-[#40444f] bg-[#1F2026] text-white'} flex justify-center items-center ${lineChartLoader ? 'visible' : 'hidden'}`} style={{
                                    transition: "all .50s",
                                }}>
                                    <img src="/asset/images/navLogo.png" alt="logo" className="w-12 animate-bounce " />
                                </div>
                            </Grid>
                        </Grid>

                        {/* <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-2 mt-7'>

                        <div className='relative'>
                            <div className={`absolute z-50 lg:h-[25rem] md:h-[25rem] sm:h-72 h-72 w-full rounded-lg border ${mode === 'light' ? 'text-gray-800 bg-white' : 'border-[#40444f] bg-[#1F2026] text-white'} flex justify-center items-center ${areaChartLoader ? 'visible' : 'hidden'}`} style={{
                                transition: "all .50s",
                            }}>
                                <img src="/asset/images/navLogo.png" alt="logo" className="w-12 animate-bounce " />
                            </div>
                            <div ref={areaChartContainerRef} className={` w-full rounded-lg border ${mode === 'light' ? 'bg-white' : ' border-[#40444f] bg-[#1F2026]'}  lg:h-[25rem] md:h-[25rem] sm:h-72 h-72`}>
                                <div className='z-10' style={{ position: 'absolute', top: 20, left: 0, color: 'white' }}>
                                    <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-5 text-base font-normal`}>Liquidity</h1>
                                    <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-5 text-xl`}>{areaPrice.price ? areaPrice.price : convertUSDDigit(tokenEtherData.tokenTotalLiquidityUSD)}{!areaPrice.price && <span className={`ms-2 ${tokenEtherData.change24hoursVolumeUSDPercentage < 0 ? 'text-red-500' : tokenEtherData.change24hoursVolumeUSDPercentage > 0 ? 'text-green-500' : mode === 'light' ? 'text-black' : ' text-white'} text-sm font-medium`}>
                                        {getDigitSign(tokenEtherData.change24hoursVolumeUSDPercentage)}</span>}</h1>
                                    <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-5 text-xs font-medium`}>{areaPrice.time && getMonthInWordWithDateAndYear(areaPrice.time)}</h1>
                                </div>
                            </div>
                        </div>

                        <div className='relative'>
                            <div className={` absolute z-50  lg:h-[25rem] md:h-[25rem] sm:h-72 h-72 w-full rounded-lg border ${mode === 'light' ? 'text-gray-800 bg-white' : 'border-[#40444f] bg-[#1F2026] text-white'} flex justify-center items-center ${lineChartLoader ? 'visible' : 'hidden'}`} style={{
                                transition: "all .50s",
                            }}>
                                <img src="/asset/images/navLogo.png" alt="logo" className="w-12 animate-bounce " />
                            </div>
                            <div ref={lineChartContainerRef} className={` w-full rounded-lg border ${mode === 'light' ? 'bg-white' : ' border-[#40444f] bg-[#1F2026]'}  lg:h-[25rem] md:h-[25rem] sm:h-72 h-72`}>
                                <div className='z-10' style={{ position: 'absolute', top: 20, left: 0, color: 'white' }}>
                                    <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-5 text-base font-normal`}>Volume (24hrs)</h1>
                                    <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-5 text-xl`}>{histogramPrice.price ? histogramPrice.price : convertUSDDigit(tokenEtherData.last24hoursTokenVolumeUSD)}{!histogramPrice.price && <span className={`ms-2 ${tokenEtherData.change24hoursVolumeUSDPercentage < 0 ? 'text-red-500' : tokenEtherData.change24hoursVolumeUSDPercentage > 0 ? 'text-green-500' : mode === 'light' ? 'text-black' : ' text-white'} text-sm font-medium`}>
                                        {getDigitSign(tokenEtherData.change24hoursVolumeUSDPercentage)}</span>}</h1>
                                    <h1 className={`${mode === 'dark' ? 'text-white' : 'text-black'} ms-5 text-xs font-medium`}>{histogramPrice.time && getMonthInWordWithDateAndYear(histogramPrice.time)}</h1>
                                </div>
                            </div>
                        </div>
                    </div> */}

                        <div>
                            <div className={`${mode === 'light' ? 'text-black' : 'text-white'}  mt-10 flex justify-between items-center`}>
                                <p className='text-lg font-semibold'>Top Tokens</p>
                                <Link to='/tokens' className='text-[#278FEA] hover:text-[#1757B1] text-sm lg:pe-4 sm:pe-0 pe-0'>See All</Link>
                            </div>

                            <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} mt-4`}>
                                {tokenDataLoader ? (
                                    <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                        <div className="flex justify-center items-center w-full h-40">
                                            <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                        </div>
                                    </div>
                                ) : (
                                    <DataGrid
                                        sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                        rows={tokenRows}
                                        columns={tokenColumns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: defaultPageSize,
                                                },
                                            },
                                        }}
                                        showColumnVerticalBorder={true}
                                        getRowId={(row) => row.index}
                                        pageSizeOptions={[defaultPageSize]}
                                        disableRowSelectionOnClick
                                        autoHeight
                                        disableColumnResize={true}
                                        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                                    />
                                )}
                            </div>
                        </div>

                        <div>
                            <div className={`${mode === 'light' ? 'text-black' : 'text-white'} text-lg mt-10 flex justify-between items-center`}>
                                <p className='text-lg font-semibold '>Top Pairs</p>
                                <div className=" lg:pe-4 sm:pe-0 pe-0 lg:flex-none sm:flex flex justify-between items-center">
                                    {/* <input id="default-checkbox" type="checkbox" value="" className="w-4 h-3  text-blue-600 bg-gray-100 border-gray-300 rounded " />
                                <label htmlFor="default-checkbox" className={`ms-2 text-sm font-medium ${mode === 'light' ? 'text-black' : 'text-white'} hover:text-gray-400 cursor-pointer`}>Hide untracked pairs</label>
                                <Tooltip title="USD amounts">
                                    <HelpOutlineIcon sx={{ color: mode === 'light' ? 'black' : 'white', fontSize: '16px', ml: 1 }} />
                                </Tooltip> */}
                                    <Link to='/pairs' className='text-[#278FEA] hover:text-[#1757B1] text-sm ml-2'>See All</Link>
                                </div>
                            </div>
                            <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} w-full mt-4`}>
                                {pairDataLoader ? (
                                    <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                        <div className="flex justify-center items-center w-full h-40">
                                            <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                        </div>
                                    </div>
                                ) : (
                                    <DataGrid
                                        sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                        rows={pairRows}
                                        columns={pairColumns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: defaultPageSize,
                                                },
                                            },
                                        }}
                                        showColumnVerticalBorder={true}
                                        getRowId={(row) => row.index}
                                        pageSizeOptions={[defaultPageSize]}
                                        disableRowSelectionOnClick
                                        autoHeight
                                        disableColumnResize={true}
                                        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                                    />
                                )}
                            </div>
                        </div>

                        <div>
                            <p className={`text-lg ${mode === 'light' ? 'text-black' : 'text-white'} font-semibold mt-10`}>Transactions</p>

                            <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} w-full mt-4`}>
                                {transactionDataLoader ? (
                                    <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                        <div className="flex justify-center items-center w-full h-40">
                                            <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                        </div>
                                    </div>
                                ) : (
                                    <DataGrid
                                        sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                        rows={transactionRows}
                                        columns={transactionsColumns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: defaultPageSize,
                                                },
                                            },
                                        }}
                                        showColumnVerticalBorder={true}
                                        getRowId={(row) => row.index}
                                        pageSizeOptions={[defaultPageSize]}
                                        disableRowSelectionOnClick
                                        autoHeight
                                        disableColumnResize={true}
                                        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>

    );
}

export default Overview;
