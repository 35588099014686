import React from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { toK } from '../allFunction/commonFunction';
import { formattedNum } from '../allFunction/commonFunction';

const LineChartComponent = ({ mode, data }) => {


    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="date"
                    tickLine={false}
                    axisLine={false}
                    minTickGap={80}
                    tickMargin={10}
                    tick={{
                        fill: mode === 'light' ? 'black' : 'white',
                        fontSize: '12px'
                    }}
                />
                <YAxis
                    orientation="right"
                    type="number"
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(tick) => '$' + toK(tick)}
                    tick={{
                        fill: mode === 'light' ? 'black' : 'white',
                        fontSize: '12px'
                    }} />


                <Tooltip
                    cursor={{ fill: 'transparent', opacity: 0.1 }}
                    formatter={(val) => formattedNum(val, true)}
                    contentStyle={{
                        padding: '10px 14px',
                        borderRadius: 10,
                        borderColor: 'black',
                        color: 'black',
                    }} />
                <Line type="monotone" dataKey="Fees" stroke="#33D778" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default LineChartComponent