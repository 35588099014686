import { Link } from 'react-router-dom';
import { convertUSDDigit, convertTokentDigit, getDigitSign } from '../allFunction/commonFunction';

const tradeUrl = process.env.REACT_APP_TRADE_URL

export const tokenColumns = [
    {
        field: 'index',
        headerName: <div className='hover:text-gray-400 hidden'>Id</div>,
        width: 5,
        sortable: false
    },
    // {
    //     field: 'lineNo',
    //     headerName: '#', 
    //     flex: 0,
    //     sortable: false,
    //     editable: false,
    //     renderCell: (params) => {
    //         // Number(params.api.getRowIndexRelativeToVisibleRows(params.row.id)) + 1,}
    //     },
    // },
    // {
    //     field: 'id',
    //     headerName: 'Number',
    //     filterable: false,
    //     renderCell: (params) => params.row.id, // Assuming 'id' field contains the row number
    // },
    {
        field: 'tokenName',
        headerName: <div className='hover:text-gray-400'>Name</div>,
        minWidth: 140,
        flex: 1,
        renderCell: (params) => {
            return <div className="text-blue-500 hover:text-blue-600 flex items-center gap-3 text-right">
                <img src={params.row.tokenIcon ? params.row.tokenIcon : "/asset/images/datagrid-icon.png"} alt="tokenimage" className='h-6 rounded-full' />
                <Link to={`/token/${params.row.tokenAddress}`}>{params.row.tokenName}</Link>
            </div>
        }
    },
    {
        field: 'tokenSymbol',
        headerName: <div className='hover:text-gray-400'>Symbol</div>,
        minWidth: 140,

        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            return <div className='text-right'>{params.row.tokenSymbol}</div>;
        }
    },
    {
        field: 'totalLiquidityUSD',
        headerName: <div className='hover:text-gray-400'>Liquidity</div>,
        minWidth: 140,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const totalLiquidityUSD = Number(params.row.totalLiquidityUSD);
            let formattedtotalLiquidityUSD = convertUSDDigit(totalLiquidityUSD)

            return (
                <div className='text-right'>
                    {formattedtotalLiquidityUSD}
                </div>
            );
        }
    },
    {
        field: 'last24hoursVolumeUSD',
        headerName: <div className='hover:text-gray-400'>Volume (24hrs)</div>,
        minWidth: 140,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const last24hoursVolumeUSD = Number(params.row.last24hoursVolumeUSD);
            let formattedtradeVolumeUSD = convertUSDDigit(last24hoursVolumeUSD)

            return (
                <div className='text-right'>
                    {formattedtradeVolumeUSD}
                </div>
            );
        }
    },
    {
        field: 'priceUSD',
        headerName: <div className='hover:text-gray-400'>Price</div>,
        minWidth: 140,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const priceUSD = Number(params.row.priceUSD);
            let formattedpriceUSD = convertUSDDigit(priceUSD)

            return (
                <div className='text-right'>
                    {formattedpriceUSD}
                </div>
            );
        }
    },
    {
        field: 'change24hoursPricePercentage',
        headerName: <div className='hover:text-gray-400 mr-1'>Price Change (24hrs)</div>,
        minWidth: 140,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const percentage = Number(params.row.change24hoursPricePercentage);
            const colorClass = percentage === 0 ? '' : percentage < 0 ? 'text-red-500' : 'text-green-500';
            const formattedPercentage = getDigitSign(percentage);

            return (
                <div className={`text-right mr-1 ${colorClass}`}>
                    {formattedPercentage}
                </div>
            );
        }
    }
];

export const pairColumns = [
    {
        field: 'index',
        headerName: <div className='hover:text-gray-400 hidden '>Id</div>,
        width: 5,
        sortable: false,
    },
    // {
    //     field: 'index',
    //     headerName: <div className='hover:text-gray-400 hidden '>Id</div>,
    //     width: 5,
    //     editable: false,
    //     sortable: false,
    //     renderCell: (params) => params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    // },
    {
        field: 'pairName',
        headerName: <div className='hover:text-gray-400'>Name</div>,
        minWidth: 180,
        flex: 1,
        renderCell: (params) => {
            return (<div className="text-blue-500 hover:text-blue-600 flex justify-start items-center">
                <img src={params.row.tokenIcon1 ? params.row.tokenIcon1 : "/asset/images/datagrid-icon.png"} alt="" className='h-5  ms-5 rounded-full' style={{ marginRight: '-32px' }} />
                <img src={params.row.tokenIcon0 ? params.row.tokenIcon0 : "/asset/images/datagrid-icon.png"} alt="" className='h-5 rounded-full z-10' />
                {params.row.pairName ? <Link to={`/pair/${params.row.pairAddress}`} className='ml-7'>{params.row.pairName}</Link> : ''}
            </div>)
        }
    },
    {
        field: 'reserveUSD',
        headerName: <div className='hover:text-gray-400'>Liquidity</div>,
        minWidth: 160,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const reserveUSD = Number(params.row.reserveUSD);
            let formattedreserveUSD = convertUSDDigit(reserveUSD)

            // if (Math.abs(reserveUSD) < 1000) {
            //     formattedreserveUSD = reserveUSD.toFixed(2);
            // } else {
            //     formattedreserveUSD = reserveUSD.toLocaleString(undefined, { maximumFractionDigits: 0 });
            // }
            return (
                <div className="text-right">
                    {formattedreserveUSD}
                </div>
            )
        }
    },
    {
        field: 'last24hoursVolumeUSD',
        headerName: <div className='hover:text-gray-400'>Volume (24hrs)</div>,
        minWidth: 160,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const last24hoursVolumeUSD = Number(params.row.last24hoursVolumeUSD);
            let formattedvolumeUSD = convertUSDDigit(last24hoursVolumeUSD);

            // if (Math.abs(last24hoursVolumeUSD) < 1000) {
            //     formattedvolumeUSD = volumeUSD.toFixed(2);
            // } else {
            //     formattedvolumeUSD = volumeUSD.toLocaleString(undefined, { maximumFractionDigits: 0 });
            // }
            return (
                <div className='text-right'>
                    {formattedvolumeUSD}
                </div>
            );
        }
    },
    {
        field: 'last7daysVolumeUSD',
        headerName: <div className='hover:text-gray-400'>Volume (7d)</div>,
        minWidth: 160,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const last7daysVolumeUSD = Number(params.row.last7daysVolumeUSD);
            let formattedlast7daysVolumeUSD = convertUSDDigit(last7daysVolumeUSD)

            // if (Math.abs(last7daysVolumeUSD) < 1000) {
            //     formattedlast7daysVolumeUSD = last7daysVolumeUSD.toFixed(2);
            // } else {
            //     formattedlast7daysVolumeUSD = last7daysVolumeUSD.toLocaleString(undefined, { maximumFractionDigits: 0 });
            // }
            return (
                <div className='text-right'>
                    {formattedlast7daysVolumeUSD}
                </div>
            );
        }
    },
    {
        field: 'last24hoursPlatformFees',
        headerName: <div className='hover:text-gray-400'>Fees (24hrs)</div>,
        minWidth: 160,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const last24hoursPlatformFees = Number(params.row.last24hoursPlatformFees);
            let formattedlast24hoursPlatformFees = convertUSDDigit(last24hoursPlatformFees);
            return (
                <div className='text-right'>
                    {formattedlast24hoursPlatformFees}
                </div>
            );
        }
    },
    {
        field: 'last7daysPlatformFees',
        headerName: <div className='hover:text-gray-400 mr-1'> Fees (7d)</div>,
        minWidth: 160,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const last7daysPlatformFees = Number(params.row.last7daysPlatformFees);
            let formattedlast7daysPlatformFees = convertUSDDigit(last7daysPlatformFees);

            // if (Math.abs(last7daysPlatformFees) < 1000) {
            //     formattedlast7daysPlatformFees = last7daysPlatformFees.toFixed(2);
            // } else {
            //     formattedlast7daysPlatformFees = last7daysPlatformFees.toLocaleString(undefined, { maximumFractionDigits: 0 });
            // }
            return (
                <div className='text-right mr-1'>
                    {formattedlast7daysPlatformFees}
                </div>
            );
        }
    },
];

export const accountColumns = [
    {
        field: 'index',
        headerName: <div className='hover:text-gray-400 hidden'>#</div>,
        width: 50,
        sortable: false
    },
    {
        field: 'accountAddress',
        headerName: <div className='hover:text-gray-400'>Account</div>,
        minWidth: 370,
        flex: 1,
        renderCell: (params) => (<div className="text-[#278FEA] hover:text-[#1757B1] cursor-pointer"><Link to={`/account/${params.row.accountAddress}`}>{params.row.accountAddress}</Link></div>)
    },
    {
        field: 'pairName',
        headerName: <div className='hover:text-gray-400'>Pair</div>,
        minWidth: 200,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => (<div className="text-blue-500 hover:text-blue-600 cursor-pointer flex justify-end items-center">
            <img src={params.row.tokenIcon0 ? params.row.tokenIcon0 : "/asset/images/datagrid-icon.png"} alt="tokenicon" className='h-5 z-10 rounded-full' />
            <img src={params.row.tokenIcon1 ? params.row.tokenIcon1 : "/asset/images/datagrid-icon.png"} alt="tokenicon" className='h-5 rounded-full' style={{ marginLeft: '-10px' }} />
            <Link to={`/pair/${params.row.pairAddress}`} className='mx-2'>{params.row.pairName}</Link>
        </div>)
    },
    {
        field: 'liquidityUSD',
        headerName: <div className='hover:text-gray-400 mr-1'>Value</div>,
        minWidth: 150,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            let formattedlast7daysliquidityUSD = convertUSDDigit(params.row.liquidityUSD)
            return <div className='text-right mr-1'>{formattedlast7daysliquidityUSD}</div>;
        }
    },
]

export const accountPositionColumn = [
    {
        field: 'index',
        headerName: <div className='hover:text-gray-400 hidden'>#</div>,
        width: 5,
        sortable: false
    },
    {
        field: 'pairName',
        headerName: <div className='hover:text-gray-400'>Name</div>,
        minWidth: 200,
        flex: 1,
        renderCell: (params) => {
            return (
                <div className='flex  flex-col h-full justify-between '>
                    <div className='flex'>
                        <div className='flex'>
                            <img src={params.row.tokenIcon1 ? params.row.tokenIcon1 : "/asset/images/datagrid-icon.png"} alt="icon" className='h-5 ms-4 mt-3 rounded-full' style={{ marginRight: '-32px' }} />
                            <img src={params.row.tokenIcon0 ? params.row.tokenIcon0 : "/asset/images/datagrid-icon.png"} alt="icon" className='h-5 rounded-full z-10 mt-3' />
                        </div>
                        <Link to={`/pair/${params.row.pairAddress}`} className='h-10 -mt-4 lg:ml-10 sm:ml-4 ml-4'>{params.row.pairName}</Link>
                    </div>
                    <div className='flex justify-start gap-2 font-medium text-xs mb-2 lg:ml-16 sm:ml-0 ml-0'>
                        <Link to={`${tradeUrl}/addLiquidity`} target='_blank' className="cursor-pointer py-1 px-2 bg-[#044e1f] hover:bg-green-800 text-white rounded-md">Add</Link>
                        <Link to={`${tradeUrl}/pool`} target='_blank' className="cursor-pointer py-1 px-2 bg-[#044e1f] hover:bg-green-800 text-white rounded-md ">Remove</Link>
                    </div>
                </div>
            )
        }
    },
    {
        field: 'liquidityUSD',
        headerName: <div className='hover:text-gray-400'>Liquidity</div>,
        minWidth: 150,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            return (
                <div className='flex flex-col -mt-4 text-right'>
                    <p className='h-[20px] '>{convertUSDDigit(params.row.liquidityUSD)}</p>
                    <p className='h-[20px] text-[11px] mt-[5px] mb-[-5px]'> {convertTokentDigit(params.row.liquidityToken0)} {params.row.tokenSymbol0}</p>
                    <p className='text-[11px]'>{convertTokentDigit(params.row.liquidityToken1)} {params.row.tokenSymbol1}</p>
                </div>
            )
        }
    },
    {
        field: 'feesUSD',
        headerName: <div className='hover:text-gray-400 mr-1'>Total Fees Earned</div>,
        minWidth: 150,
        flex: 1,
        headerAlign: 'right',
        renderCell: (params) => {
            const percentage = Number(params.row.feesUSD);
            const colorClass = percentage === 0 ? '' : percentage < 0 ? 'text-red-500' : 'text-green-500';
            return (
                <div className='flex flex-col -mt-4 text-right mr-1'>
                    <p className={`h-[20px] ${colorClass}`}>{convertUSDDigit(params.row.feesUSD)}</p>
                    <p className='h-[20px] text-[11px] mt-[5px] mb-[-5px]'> {convertTokentDigit(params.row.feesToken0)} {params.row.tokenSymbol0}</p>
                    <p className='text-[11px]'>  {convertTokentDigit(params.row.feesToken1)} {params.row.tokenSymbol1}</p>
                </div>
            )
        }
    },
];


