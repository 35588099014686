import axios from "axios"

const apiBaseUrl = process.env.REACT_APP_APIBASEURL

export const fetchAccountDetail = async () => {
    const response = await axios.get(`${apiBaseUrl}/account/account-list`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchAccountWalletDetail = async (accountAddress) => {

    const response = await axios.post(`${apiBaseUrl}/account/account-details/${accountAddress}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchAccountTransactionDetail = async (actionType, accountAddress) => {
    const response = await axios.post(`${apiBaseUrl}/account/account-transaction/${accountAddress}`, {
        actionType
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchAccountPositionDetail = async (accountAddress, pairAddress) => {
    const response = await axios.post(`${apiBaseUrl}/account/position-list/${accountAddress}`, {
        pairAddress
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchAccountChartDetail = async (periodType, pairAddress, accountAddress) => {
    const response = await axios.post(`${apiBaseUrl}/account/account-chart/${accountAddress}`, {
        periodType,
        pairAddress
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}
export const fetchAccountFeesChartDetail = async (periodType, pairAddress, accountAddress) => {
    const response = await axios.post(`${apiBaseUrl}/account/account-fees-chart/${accountAddress}`, {
        periodType,
        pairAddress
    },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}

export const fetchAllPositonData = async (accountAddress) => {
    const response = await axios.get(`${apiBaseUrl}/account/all-position-list/${accountAddress}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        },
    )
    return response.data
}



