import { defaultRowMessage } from "../constant/variable";
import { GridOverlay } from "@mui/x-data-grid";
import Numeral from 'numeral'
import { Bounce, toast } from "react-toastify";

export const truncateAddress = (address) => {
    if (!address) {
        return '';
    }
    return address.slice(0, 6) + "..." + address.slice(-4);
};

export const CustomNoRowsOverlay = () => {
    const mode = localStorage.getItem('mode')
    return (
        <GridOverlay>
            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', backgroundColor: mode === 'light' ? 'white' : '#23242A', width: '100%'
            }} >
                <div>{defaultRowMessage}</div>
            </div>
        </GridOverlay >
    );
};

export const timeAgo = (dateString) => {
    const date = new Date(dateString);
    var now = new Date();
    now.toUTCString();
    const diff = now - date;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
        return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
};

export function getMonthInWordWithDateAndYear(dateString) {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();
    const day = String(date.getUTCDate()).padStart(2, '0');

    const monthName = months[monthIndex];

    return `${monthName} ${day}, ${year}`;
}

export function getMonthInWordWithDate(dateString) {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);
    const monthIndex = date.getUTCMonth();
    const day = String(date.getUTCDate()).padStart(2, '0');

    const monthName = months[monthIndex];

    return `${monthName} ${day}`;
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month index
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${year}-${month}-${day}`;
}
export const formatDateWithTime = (timeStamp) => {
    const date = new Date(timeStamp);
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month index
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const meridiem = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12; // Convert 0 to 12-hour format

    return `${month}/${day} ${displayHours}:${minutes} ${meridiem} UTC`;
}

export const convertTokentDigit = (num) => {
    // const digit = Number(num)
    // if (digit < 0.0001) {
    //     return `< 0.0001`
    // } else if (digit < 1) {
    //     return digit.toFixed(4)
    // } else if (digit > 1000) {
    //     return digit.toLocaleString(undefined, { maximumFractionDigits: 0 })
    // } else {
    //     return digit.toFixed(2)
    // }
    return formattedNum(num)
}

export const convertUSDDigit = (num) => {
    // const digit = Number(num)
    // if (digit > 1000) {
    //     return digit.toLocaleString(undefined, { maximumFractionDigits: 0 })
    // } else {
    //     return digit.toFixed(2)
    // }
    return formattedNum(num, true)
}

export const getDigitSign = (num) => {
    if (num === undefined || num === null) {
        return;
    }
    const digit = Number(num)
    if (digit === 0) {
        return '0.00%'
    } else if (digit > 0) {
        return `+${(num).toFixed(2)}%`
    } else {
        return `${(num).toFixed(2)}%`
    }

}

export const toK = (num) => {
    return Numeral(num).format('0.[00]a')
}

export const formatDollarAmount = (num, digits) => {
    const formatter = new Intl.NumberFormat([], {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    })
    return formatter.format(num)
}

export const formattedNum = (number, usd = false, acceptNegatives = false) => {
    if (isNaN(number) || number === '' || number === undefined) {
        return usd ? '$0' : 0
    }
    let num = parseFloat(number)

    if (num > 500000000) {
        return (usd ? '$' : '') + toK(num.toFixed(0), true)
    }

    if (num === 0) {
        if (usd) {
            return '$0'
        }
        return 0
    }

    if (num < 0.0001 && num > 0) {
        return usd ? '< $0.0001' : '< 0.0001'
    }

    if (num > 1000) {
        return usd ? formatDollarAmount(num, 0) : Number(parseFloat(num).toFixed(0)).toLocaleString()
    }

    if (usd) {
        if (num < 0.1) {
            return formatDollarAmount(num, 4)
        } else {
            return formatDollarAmount(num, 2)
        }
    }

    return Number(parseFloat(num).toFixed(4)).toString()
}

export const showErrorToast = (msg) => {
    toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
};

