import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import ListIcon from '@mui/icons-material/List';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Header = (props) => {

    const [seconds, setSeconds] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const virothiumUrl = process.env.REACT_APP_VIROTHIUM_URL
    const discordurl = process.env.REACT_APP_DISCORD_URL
    const twitterUrl = process.env.REACT_APP_TWITTER_URL

    const location = useLocation();
    const { toggleMode, mode } = props

    const closeDialog = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds + 1);
        }, 1000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [location]);

    return (
        <div>
            {/* <p className='bg-[#27AE60] lg:ml-[240px] lg:max-w-[100%] text-white text-center font-medium p-3 text-base'>Explore the new combined V2 and V3 analytics at app.virothium.org</p> */}
            {/* <nav className="lg:hidden md:block sm:block block bg-[#0E0F12] border-gray-200 px-4 py-2.5">
                <div className="flex justify-between items-center space-x-3 font-medium w-full text-gray-400">
                    <div>
                        <Link to='/'>
                            <img src="./asset/images/navLogo.png" alt="logo" className="w-12" />
                        </Link>
                    </div>
                    <div className='flex flex-row'>
                        <Link to="/" className={`block py-2 ${location.pathname === '/' ? 'text-white' : 'text-gray-400'} md:text-base text-sm mx-2`} aria-current={location.pathname === '/' ? 'page' : undefined}>Overview</Link>
                        <Link to="/tokens" className={`block py-2 ${location.pathname === '/tokens' || location.pathname === '/token' ? 'text-white' : 'text-gray-400'} md:text-base text-sm mx-2`}>Tokens</Link>
                        <Link to="/pairs" className={`block py-2 ${location.pathname === '/pairs' || location.pathname === '/pair' ? 'text-white' : 'text-gray-400'} md:text-base text-sm mx-2`}>Pairs</Link>
                        <Link to="/accounts" className={`block py-2 ${location.pathname === '/accounts' || /^\/account\/[a-zA-Z0-9]+$/.test(location.pathname) ? 'text-white' : 'text-gray-400'} md:text-base text-sm mx-2`}>Accounts</Link>
                    </div>
                </div>
            </nav> */}

            <header className="bg-[#0E0F12]">
                <nav className="mx-auto lg:hidden flex max-w-7xl items-center justify-between px-3.5 py-2" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href='/'>
                            <img src="/asset/images/navLogo.png" alt="logo" className="w-12" />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white" onClick={() => setIsOpen(true)}>
                            <span className="sr-only">Open main menu</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 12h18M3 6h18M3 18h18"></path>
                            </svg>
                        </button>
                    </div>
                </nav>

                {isOpen && (
                    <div className="lg:hidden " role="dialog" aria-modal="true" >
                        <div className="fixed inset-0 z-50"></div>
                        <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#0E0F12] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div className="flex items-center justify-between">
                                <a href='/'>
                                    <img src="/asset/images/navLogo.png" alt="logo" className="w-12" />
                                </a>
                                <button type="button" className="-m-2.5 rounded-md p-2.5 text-white" onClick={closeDialog}>
                                    <span className="sr-only">Close menu</span>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                    </svg>
                                </button>
                            </div>

                            <Link to="/" className={`block py-2 ${location.pathname === '/' ? 'text-white' : 'text-gray-400'} md:text-base text-sm flex justify-start mt-2 `} onClick={closeDialog} aria-current={location.pathname === '/' ? 'page' : undefined}>
                                <p><TrendingUpIcon sx={{ fontSize: '21px' }} /></p>
                                <p className='ml-2'>Overview</p>
                            </Link>

                            <Link to="/tokens" className={`block py-2 ${location.pathname === '/tokens' || /^\/token\/[a-zA-Z0-9]+$/.test(location.pathname) ? 'text-white' : 'text-gray-400'} md:text-base text-sm flex justify-start`} onClick={closeDialog}>
                                <p><ModeStandbyIcon sx={{ fontSize: '21px' }} /></p>
                                <p className='ml-2'>Tokens</p>
                            </Link>

                            <Link to="/pairs" className={`block py-2 ${location.pathname === '/pairs' || /^\/pair\/[a-zA-Z0-9]+$/.test(location.pathname) ? 'text-white' : 'text-gray-400'} md:text-base text-sm flex justify-start `} onClick={closeDialog}>
                                <p><PieChartOutlineIcon sx={{ fontSize: '21px' }} /></p>
                                <p className='ml-2'>Pairs</p>
                            </Link>

                            <Link to="/accounts" className={`block py-2 ${location.pathname === '/accounts' || /^\/account\/[a-zA-Z0-9]+$/.test(location.pathname) ? 'text-white' : 'text-gray-400'} md:text-base text-sm flex justify-start`} onClick={closeDialog}>
                                <p><ListIcon sx={{ fontSize: '21px' }} /></p>
                                <p className='ml-2'>Accounts</p>
                            </Link>

                            <button className='flex justify-start text-gray-400  mt-5' onClick={toggleMode}>
                                {mode === 'light' ? (
                                    <>
                                        <LightModeIcon sx={{ fontSize: 20, color: '#FFF', marginRight: '5px', marginTop: '3px' }} onClick={closeDialog} /> /
                                        <NightlightRoundIcon sx={{ fontSize: 20, transform: 'rotate(-42.55deg)', marginLeft: '5px', marginTop: '4px' }} onClick={closeDialog} />
                                    </>
                                ) : (
                                    <>
                                        <LightModeIcon sx={{ fontSize: 20, marginRight: '5px', marginTop: '3px' }} onClick={closeDialog} /> /
                                        <NightlightRoundIcon sx={{ fontSize: 20, color: '#FFF', transform: 'rotate(-42.55deg)', marginLeft: '5px', marginTop: '4px' }} onClick={closeDialog} />
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </header>

            <aside id="logo-sidebar" className="lg:block md:hidden sm:hidden hidden fixed top-0 left-0 z-40 w-60 bg-[#0E0F12] h-screen" aria-label="Sidebar">
                <div className="h-full px-5 pb-4 overflow-y-auto flex flex-col justify-between ">
                    <div>
                        <a className='flex justify-center items-center mb-3 mt-3' href='/'>
                            <img src="/asset/images/navLogo.png" alt="logo" className="w-[50px] h-[50px] " />
                            <img src="/asset/images/navNameLogo.png" alt="logo" className="w-[140px]" />
                        </a>

                        <Link to="/" className={`${location.pathname === '/' ? 'text-white' : 'text-gray-400'} ml-2 hover:text-white flex justify-start items-center cursor-pointer mt-[20px]`}>
                            <p><TrendingUpIcon sx={{ fontSize: '21px' }} /></p>
                            <p className='ml-2 text-[14px]'>Overview</p>
                        </Link>

                        <Link className={`${location.pathname === '/tokens' || /^\/token\/[a-zA-Z0-9]+$/.test(location.pathname) ? 'text-white' : 'text-gray-400'} mt-4 hover:text-white flex justify-start items-center cursor-pointer ml-2`} to="/tokens">
                            <p><ModeStandbyIcon sx={{ fontSize: '21px' }} /></p>
                            <p className='ml-2 text-[14px]'>Tokens</p>
                        </Link>

                        <Link className={`${location.pathname === '/pairs' || /^\/pair\/[a-zA-Z0-9]+$/.test(location.pathname) ? 'text-white' : 'text-gray-400'} mt-4 hover:text-white flex justify-start items-center cursor-pointer ml-2`} to="/pairs">
                            <p><PieChartOutlineIcon sx={{ fontSize: '21px' }} /></p>
                            <p className='ml-2 text-[14px]'>Pairs</p>
                        </Link>

                        <Link className={`${location.pathname === '/accounts' || /^\/account\/[a-zA-Z0-9]+$/.test(location.pathname) ? 'text-white' : 'text-gray-400'} mt-4 hover:text-white flex justify-start items-center cursor-pointer ml-2 mb-[20px]`} to="/accounts">
                            <p><ListIcon sx={{ fontSize: '21px' }} /></p>
                            <p className='ml-2 text-[14px]'>Accounts</p>
                        </Link>

                    </div>
                    <div className='ml-2' >
                        <div className='text-gray-300  cursor-pointer font-medium text-sm'>
                            <p className='hover:text-white'><Link to={virothiumUrl} target='_blank'>Virothium</Link></p>
                            {/* <p className='hover:text-white mt-1'>V1 Analytics</p> */}
                            {/* <p className='hover:text-white mt-1'>Docs</p> */}
                            <p className='hover:text-white mt-1'><Link to={discordurl} target='_blank'>Discord</Link></p>
                            <p className='hover:text-white mt-1'><Link to={twitterUrl} target='_blank'>Twitter</Link></p>
                        </div>
                        <button className='flex justify-start text-gray-400  mt-5' onClick={toggleMode}>
                            {mode === 'light' ? (
                                <>
                                    <LightModeIcon sx={{ fontSize: 20, color: '#FFF', marginRight: '5px', marginTop: '3px' }} /> /
                                    <NightlightRoundIcon sx={{ fontSize: 20, transform: 'rotate(-42.55deg)', marginLeft: '5px', marginTop: '4px' }} />
                                </>
                            ) : (
                                <>
                                    <LightModeIcon sx={{ fontSize: 20, marginRight: '5px', marginTop: '3px' }} /> /
                                    <NightlightRoundIcon sx={{ fontSize: 20, color: '#FFF', transform: 'rotate(-42.55deg)', marginLeft: '5px', marginTop: '4px' }} />
                                </>
                            )}
                        </button>
                        <div className=' mt-5 text-gray-400 hover:text-white text-[0.8rem]' >
                            <a href='/'>Updated {seconds}s ago</a>
                        </div>
                    </div>
                </div>
            </aside>

        </div>
    )
}

export default Header;
