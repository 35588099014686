import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { CustomNoRowsOverlay, showErrorToast } from '../../allFunction/commonFunction';
import { fetchAccountDetail } from '../../api/account';
import { accountColumns } from '../../constant/data';
import SearchDropdown from '../../component/SearchDropdown';

const Accounts = (props) => {

    const { mode } = props
    // const [savedAccounts, setSavedAccounts] = useState([])
    const [allAccountData, setAllAccountData] = useState([])

    //loader State
    const [accountDataLoader, setAccountDataLoader] = useState(true);

    const rows = allAccountData.map((row, index) => ({ ...row, index: index + 1 }));

    const getAccountData = async () => {
        try {
            setAccountDataLoader(true);
            const pairData = await fetchAccountDetail()
            if (!pairData.success) {
                showErrorToast(pairData.message)
            }
            setAllAccountData(pairData.data);
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setAccountDataLoader(false);
        }
    }

    // const handleAccount = (accountValue) => {
    //     navigate(`/account/${accountValue}`)
    //     const trimmedAccountValue = accountValue.trim();
    //     const accountDetails = JSON.parse(localStorage.getItem('accounts'))
    //     if (!accountDetails) {
    //         localStorage.setItem('accounts', JSON.stringify([trimmedAccountValue]))
    //     } else {

    //         const accountExists = accountDetails.find(account => account === trimmedAccountValue);
    //         if (accountExists) {
    //             // Redirect to the existing account page
    //             navigate(`/account/${trimmedAccountValue}`);
    //         } else {
    //             const updatedAccounts = [...accountDetails, trimmedAccountValue];
    //             localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
    //         }
    //     }
    // }

    // const savedAccountsNavigate = (item) => {
    //     navigate(`/account/${item}`)
    // }

    // const handleRemoveAccount = (index) => {
    //     const deleteAccount = [...savedAccounts]
    //     deleteAccount.splice(index, 1)
    //     setSavedAccounts(deleteAccount)
    //     localStorage.setItem('accounts', JSON.stringify(deleteAccount))
    // }

    // useEffect(() => {
    //     const accountDetails = JSON.parse(localStorage.getItem('accounts'))
    //     if (accountDetails) {
    //         setSavedAccounts(accountDetails)
    //     }
    // }, [])

    useEffect(() => {
        getAccountData()
        // eslint-disable-next-line
    }, [])

    return (
        <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#23242A]'} mb-16`}>
            <div className='p-4 lg:ml-[240px] lg:max-w-[100%]'>
                <div className="lg:max-w-[1440px] lg:mx-auto" >
                    <div className='lg:flex md:flex justify-between items-center mt-4 w-full'>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} text-2xl font-semibold`}>Top Liquidity Positions</p>
                        <div className="relative lg:mt-0 md:mt-0 sm:mt-2 mt-4 lg:block md:block sm:hidden hidden w-80">
                            <SearchDropdown mode={mode} />
                        </div>
                    </div>

                    {/* <div className="relative w-full flex lg:mt-8 md:mt-8 mt-5 items-center">
                        <input
                            type="search"
                            className={`relative m-0 block flex-auto rounded-2xl border ${mode === 'light' ? ' text-black' : ' border-[#40444F] text-white'} bg-transparent px-3 py-[0.25rem] text-base outline-none transition duration-200 ease-in-out placeholder:text-[#6C7284] h-12 w-2`}
                            placeholder="0x..."
                            aria-label="Search"
                            id="exampleFormControlInput2"
                            aria-describedby="button-addon2"
                            value={accountValue}
                            onChange={handleInputChange} />

                        <button
                            onClick={() => handleAccount(accountValue)}
                            className={`flex items-center p-3 text-sm font-bold rounded-xl ml-3 cursor-pointer h-9 ${mode === 'light' ? 'bg-[#caf1db] text-[#27AE60] hover:bg-[#b7eecf]' : 'text-[#165bbd] bg-[#2172e51a]  hover:bg-[#1E2D45] '}`}
                            id="button-addon2">
                            Load Account Details
                        </button>
                    </div>

                    <div>
                        <div className={`block  w-full mt-4 p-4 bg-transparent ${mode === 'light' ? 'border' : 'border border-[#40444F]'}  rounded-lg `}>
                            <h5 className={` ${mode === 'light' ? 'text-black' : 'text-white '} mb-4 text-base font-semibold`}>Saved Accounts</h5>
                            <hr className={`${mode === 'light' ? '' : 'border-[#40444F]'} `} />

                            {savedAccounts.length > 0 ? savedAccounts.map((item, index) => {
                                return <div key={index} className='flex justify-between mt-4 cursor-pointer'>
                                    <p className='text-blue-500 font-semibold whitespace-nowrap overflow-hidden text-ellipsis' onClick={() => savedAccountsNavigate(item)}>{item}</p>
                                    <CloseIcon sx={{ fontSize: '17px' }} onClick={() => handleRemoveAccount(index)} className={` ${mode === 'light' ? 'text-black' : 'text-white '} cursor-pointer items-center lg:mt-0 md:mt-0 sm:mt-0 mt-1`} />
                                </div>
                            }) :
                                <p className="font-normal text-[#6C7284] mt-4">No saved accounts</p>
                            }
                        </div>
                    </div> */}

                    <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} w-full mt-6 `} >
                        {accountDataLoader ? (
                            <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                <div className="flex justify-center items-center w-full h-40">
                                    <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                </div>
                            </div>
                        ) : (
                            <DataGrid
                                sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                rows={rows}
                                columns={accountColumns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 100,
                                        },
                                    },
                                }}
                                showColumnVerticalBorder={true}
                                pageSizeOptions={[100]}
                                disableRowSelectionOnClick
                                getRowId={(row) => row.index}
                                autoHeight
                                disableColumnResize={true}
                                slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accounts
