import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { tokenColumns } from '../../constant/data';
import { fetchTokenDetail } from '../../api/token';
import { CustomNoRowsOverlay, showErrorToast } from '../../allFunction/commonFunction';
import SearchDropdown from '../../component/SearchDropdown';

const Tokens = (props) => {

    //tokenData state
    const [allTokenData, setAllTokenData] = useState([]);

    //loader state
    const [tokenDataLoader, setTokenDataLoader] = useState(true);

    const { mode } = props;

    const rows = allTokenData.map((row, index) => ({ ...row, index: index + 1 }));

    const getTokenData = async () => {
        try {
            setTokenDataLoader(true);
            const tokensData = await fetchTokenDetail();
            if (!tokensData.success) {
                showErrorToast(tokensData.message)
            }
            setAllTokenData(tokensData.data);
        } catch (error) {
            showErrorToast(error.message)
        } finally {
            setTokenDataLoader(false)
        }
    };

    useEffect(() => {
        getTokenData();
        // eslint-disable-next-line 
    }, []);

    return (
        <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#23242A]'} mb-16`}>
            <div className='p-4 lg:ml-[240px] lg:max-w-[100%]'>
                <div className="lg:max-w-[1440px] lg:mx-auto">
                    <div className='text-white font-semibold text-lg mt-4 lg:flex md:flex justify-between items-center'>
                        <p className={`${mode === 'light' ? 'text-black' : 'text-white'} text-2xl`}>Top Tokens</p>
                        <div className="relative lg:mt-0 md:mt-0 sm:mt-2 mt-4 lg:block md:block sm:hidden hidden w-80">
                            <SearchDropdown mode={mode} />
                        </div>
                    </div>

                    <div className={`${mode === 'light' ? 'bg-white' : 'bg-[#1F2026]'} w-full mt-6`}>
                        {tokenDataLoader ? (
                            <div className={`${mode === 'dark' ? '' : 'border border-[#E0E0E0]'} rounded-md`}>
                                <div className="flex justify-center items-center w-full h-40">
                                    <img src="/asset/images/navLogo.png" alt="Loading" className="w-12 animate-bounce" />
                                </div>
                            </div>
                        ) : (
                            <DataGrid
                                sx={{ color: mode === 'light' ? 'black' : 'white' }}
                                rows={rows}
                                columns={tokenColumns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 100,
                                        },
                                    },
                                }}
                                showColumnVerticalBorder={true}
                                getRowId={(row) => row.index}
                                pageSizeOptions={[100]}
                                disableRowSelectionOnClick
                                autoHeight
                                disableColumnResize={true}
                                slots={{ noRowsOverlay: CustomNoRowsOverlay }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tokens;
